
export class Payment {
    id: number; 
    phonePeUrl:string;
    name: string;
    date: string;
    email: string;
    businessEmail: string;
    amount: number;
    currency: string;
    state: string;
    token: string;
    expYear: string;
    expMonth: string;
    cardNumber: string;
    callbackUrl: string;
    cvv: string;
    description: string;
    status: string;
    receiptNumber: string;
    referenceNumber: string;
    failureMessage: string;
    failureCode: string;
    paymentMode: string;
    externalReference: string;
    propertyId: number;
    deliveryChargeAmount: number;
  
    firstName: string;
    lastName: string;
  
    businessName: string;
    transactionAmount: number;
    transactionChargeAmount: number;
    netReceivableAmount: number;
    otherChargesAmount: number;
    taxAmount: number;
    clientSecret: string;
    orderId: number;
    businessServiceName: string;
    roomNumber: string;
    customerName: string;
    
    constructor() {
    }
}
