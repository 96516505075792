import { json } from 'd3';
import { productVariationDtoList } from "src/app/model/productVariation";
import { TokenStorage } from "src/app/token.storage";

import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from "@angular/core";
import { ItemsList } from "@ng-select/ng-select/lib/items-list";
import { DataModel } from "src/app/model/DataModel";
import { AddToCart } from "src/app/model/addToCartDTO";

@Component({
  selector: "cartOne-product",
  template: `
     <div
      class="cart-item"
      *ngIf="product != null && product.variationDtoList == undefined"
    >
      <div class="row">
        <div class="col-12" style=" height: 70px;">
          <div style="float: left;">
            <img
              *ngIf="product.imageList?.length"
              [src]="product.imageList[0].url"
              alt=""
              width="60"
              height="60"
            />
            <img
              *ngIf="!product.imageList?.length"
              src="/assets/images/fast-food.png"
              alt=""
              width="60"
              height="60"
            />
          </div>
          <div class="box-flex product-card-attributes text-product-name" style="color:black">
            <span class="ml-2"
              >{{ product.name }}
              <div
                class="badge badge-primary ml-2"
                *ngIf="
                  product.discountedPrice !== null &&
                  product.discountedPrice !== 0
                "
              >
                {{
                  100 - (100 / product.sellUnitPrice) * product.discountedPrice
                    | number: "1.0-2"
                }}% Off
              </div>
            </span>
            <span
              style=" top: 70px;    display: flex;margin-top:10px;padding-left: 0.5rem;color: black;"
            >
              <span class="mr-3" *ngIf="isDisabled"
                >Qty: {{ product.quantityProduct }}</span
              >
              <span
                class="discounted-price" style="color:black"
                *ngIf="
                  product.discountedPrice !== null &&
                  product.discountedPrice !== 0
                "
              >
                {{
                  product.sellUnitPrice * product.quantityProduct
                    | number: "1.2-2"
                }} </span
              >{{ product.totalPrice | number: "1.2-2" }}
            </span>
            <br />
            <!-- <p class="cart-description">{{ product.description }}</p> -->
          </div>
          <div
            class="product-image-and-manager-container"
            *ngIf="!isDisabled"
            style="float: right; margin-left: 20px; right: 12px; top: 19px; position: absolute;color: black;"
          >
            <div class="product-card-instant-card-manager-container" style="color:black">
              <div
                class="box-flex instant-cart-manager instant-cart-manager-variant-big bg-white cl-brand-primary ai-center jc-end  fd-row"
                style="position: inherit !important; color: black;"
              >
                <button
                  class="instant-cart-manager-dec-button"
                  *ngIf="product.quantityProduct == 1"
                  aria-label="Click to decrease product amount instantly"
                >
                  <img
                    class="svg-icon-color"
                    src="../../../assets/images/bin.svg"
                    alt=""
                    (click)="modelChangedProductMinus(product)"
                  />
                </button>
                <button
                  class="instant-cart-manager-dec-button"
                  *ngIf="product.quantityProduct > 1"
                  aria-label="Click to decrease product amount instantly"
                >
                  <img
                    class="svg-icon-color"
                    src="../../../assets/images/minus.svg"
                    alt=""
                    (click)="modelChangedProductMinus(product)"
                  />
                </button>
                <span class="instant-cart-manager-quantity" style="color:black">{{
                  product.quantityProduct
                }}</span>
                <input
                  class="pull-right form-control pl-2 pr-2"
                  style="margin-right: 10px; width: 50px; height: 30px; display:none; color: black;"
                  type="number"
                  [(ngModel)]="product.quantityProduct"
                  min="0"
                  (ngModelChange)="modelChangedProduct(product)"
                  (ngModelChange)="modelChangedProductQty()"
                />
                <button
                  class="instant-cart-manager-inc-button"
                  aria-label="Click to increase product amount instantly"
                >
                  <img
                    src="../../../assets/images/plus.svg"
                    alt=""
                    class="svg-icon-color"
                    (click)="modelChangedProductPlus(product)"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div *ngIf="product.productDtoList">
      <span
        style="font-size:17px;margin-top:5px;font-family: 'Inria Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;color: black;
"
        >Extras</span
      ><br />
     
       <div *ngFor="let item of product.productDtoList">
        <div
          class="form-check"
          style="display: flex;justify-content: space-between;color: black;"
        >
        <div>
        <input class="form-check-input" type="checkbox" [value]="item.sellUnitPrice" id="defaultCheck1" (change)="onChange( $event ,product,item.id,i)" style="color:black">
        
          <label class="form-check-label" for="defaultCheck1">
            <h6
              style="font-family: 'Secular One';color: black;
font-style: normal;
font-weight: 400;
font-size: 16px;"
            >
              {{ item.name }}
            </h6>
          </label>
          </div>
         <div> <h6 style="color:black">{{ item.sellUnitPrice }}</h6></div>
        </div>
      </div> 

     
      </div>
      <div *ngIf="product.toppingproductDtoList">
      <span
        style="font-size:17px;margin-top:5px;font-family: 'Inria Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;color: black;
" *ngIf="product?.toppingproductDtoList.length >0"
        >Toppings</span
      ><br />
      <div *ngFor="let item of product.toppingproductDtoList">
        <div
          class="form-check"
          style="display: flex;justify-content: space-between;color: black;"
        >
        <div>
        <input class="form-check-input" style="color:black"
        type="checkbox" [value]="item.sellUnitPrice" id="defaultCheck1" (change)="onChange( $event ,product,item.id,i)">
        
          <label class="form-check-label" for="defaultCheck1" style="color:black">
            <h6
              style="font-family: 'Secular One';
font-style: normal;
font-weight: 400;color: black;
font-size: 16px;"
            >
              {{ item.name }}
            </h6>
          </label>
          </div>
         <div> <h6 style="color:black">{{ item.sellUnitPrice }}</h6></div>
        </div>
      </div> 
      </div>
      <div class=" mt-2">
        <div style=" width: 100%;  display: block;">
          <label *ngIf="!isDisabled" class="form-label" for="mobile"style="color:black"
            >Notes</label
          >
          <button
            class="btn btn-info btn-sm pull-right"
            (click)="addNoteToItems()"
            *ngIf="!isDisabled && product.notes == null"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
          <textarea
            *ngIf="product.notes != null"
            type="text"
            placeholder="Notes"
            class="form-control form-control-alternative mb-3"
            id="note"
            name="note"
            [(ngModel)]="product.notes"
            #email="ngModel"
            [readonly]="isDisabled"
          ></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="product != null && product.variationDtoList != undefined">
      <div
        class="cart-item row"
        *ngFor="let item of product.variationDtoList; let i = index"
      >
        <div class="col-12" style=" height: 95px;">
          <div style="float: left;">
            <img
              *ngIf="product.imageList?.length"
              [src]="product.imageList[0].url"
              alt=""
              width="60"
              height="60"
            />
            <img
              *ngIf="!product.imageList?.length"
              src="/assets/images/fast-food.png"
              alt=""
              width="60"
              height="60"
            />
          </div>

          <div class="box-flex product-card-attributes text-product-name" style="color:black">
            <span class="ml-2" style="color:black"
              >{{ product.name }}- {{ item.name }}
              <div style="color:black"
                class="badge badge-primary ml-2"
                *ngIf="
                  item.discountedPrice !== null && item.discountedPrice !== 0
                "
              >
                {{
                  100 - (100 / item.sellUnitPrice) * item.discountedPrice
                    | number: "1.0-2"
                }}% Off
              </div> </span
            ><br />
            <!-- <p class="cart-description">{{ product.description }}</p> -->
            <!-- <span> {{ product.shortDescription }}</span> -->
            <span
              style="float: left;
                left: 83px;
                top: 33px;
                position: absolute; color: black;"
              ><span class="mr-3" *ngIf="isDisabled" style="color:black">
                Qty: {{ item.quantityVariation }}
              </span>
              <span style="color:black"
                class="discounted-price"
                *ngIf="
                  item.discountedPrice !== null && item.discountedPrice !== 0
                "
              >
                {{
                  item.sellUnitPrice * item.quantityVariation | number: "1.2-2"
                }}
              </span>
              {{ item.totalPrice | number: "1.2-2" }}</span
            >
          </div>
          <div class="product-image-and-manager-container" *ngIf="!isDisabled" style="color:black">
            <div class="product-card-instant-card-manager-container" style="color:black">
              <div style="color:black"
                class="box-flex instant-cart-manager instant-cart-manager-variant-big bg-white cl-brand-primary ai-center jc-center  fd-row"
              >
                <button
                  class="instant-cart-manager-dec-button"
                  *ngIf="item.quantityVariation == 1"
                  aria-label="Click to decrease product amount instantly"
                >
                  <img
                    src="../../../assets/images/bin.svg"
                    alt=""
                    class="svg-icon-color"
                    (click)="modelChangedVariationMinus(item, i)"
                  />
                </button>
                <button
                  class="instant-cart-manager-dec-button"
                  *ngIf="item.quantityVariation > 1"
                  aria-label="Click to decrease product amount instantly"
                >
                  <img
                    src="../../../assets/images/minus.svg"
                    alt=""
                    class="svg-icon-color"
                    (click)="modelChangedVariationMinus(item, i)"
                  />
                </button>
                <span class="instant-cart-manager-quantity" style="color:black">{{
                  item.quantityVariation
                }}</span>
                <input
                  class="pull-right form-control pl-2 pr-2 "
                  style="margin-right: 10px; width: 50px; height: 30px; display:none; color: black;"
                  type="number"
                  [(ngModel)]="item.quantityVariation"
                  min="0"
                  (ngModelChange)="modelChangedVariation(item, i)"
                  (ngModelChange)="modelChangedProductQty()"
                />
                <button
                  class="instant-cart-manager-inc-button"
                  aria-label="Click to increase product amount instantly"
                >
                  <img
                    src="../../../assets/images/plus.svg"
                    alt=""
                    class="svg-icon-color"
                    (click)="modelChangedVariationPlus(item, i)"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-2" style=" width: 100%;  display: inline-block;color: black;">
          <label *ngIf="!isDisabled" class="form-label" for="mobile" style="color:black"
            >Notes</label
          >
          <button
            class="btn btn-info btn-sm pull-right"
            (click)="addNoteToItemsVariation(i)"
            *ngIf="!isDisabled && item.notes == null"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
          <textarea
          style="color:black"
            *ngIf="item.notes != null"
            type="text"
            placeholder="Notes"
            class="form-control form-control-alternative mb-3"
            id="note"
            name="note"
            [(ngModel)]="item.notes"
            #email="ngModel"
            [readonly]="isDisabled"
          >
          </textarea>
        </div>
      </div>
    </div>

    <hr  />
  `,
  styles: [
    `
      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-top: 3px solid rgba(0, 0, 0, 0.1);

      }
      .cart-description {
        font-size: small;
        font-weight: 500;
        padding-left: 8px;
        height: 25px;
        /* width: 100%; */
        padding-right: 8px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      :host > div {
        /* display: flex !important; */
        /* grid-template-columns: 1fr 25px; */
        /* grid-column-gap: 10px; */
        padding: 0px 20px;
      }
      div > ul {
        padding-inline-start: 0px !important;
      }
      div:nth-child(1) {
        font-weight: bold;
        flex-grow: 1 !important;
      }
      .pricing-list-container ul li {
        padding: 10px 0;
      }
      .pricing-list-container ul:last-child {
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .pricing-list-container span {
        right: 10px !important;
        top: 50% !important;
      }
      .instant-cart-manager-quantity {
        width: 50px !important;
        float: right;
      }
      .cart-item {
        position: relative;
        display: block;
        /* height: 100px; */
        margin-top: 20px;
      }
      .discounted-price {
        text-decoration: line-through;
        font-size: smaller;
        font-weight: 400;
      }

      .product-group .button {
        display: flex;
      }

      .product-card
        .product-image-and-manager-container
        .product-image-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .product-card
        .product-image-and-manager-container
        .product-image-container:before {
        display: block;
        background-color: rgba(51, 51, 51, 0.04);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
      }

      .product-card
        .product-image-and-manager-container
        .product-image-container
        .product-image.product-image-loaded {
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: auto 80%;
      }

      .product-card .product-image-container .product-image {
        width: 140px;
        height: 140px;
        background-size: cover;
      }

      .product-card
        .product-image-and-manager-container
        .product-image-container
        .product-image {
        width: 100%;
        height: 100%;
      }

      .box-flex.instant-cart-manager.instant-cart-manager-variant-big {
        position: absolute;
    z-index: 10;
    right: 5px;
    top: 23px;
      }

      .instant-cart-manager .instant-cart-manager-variant-big {
        width: 40px;
      }

      .instant-cart-manager-dec-button,
      .instant-cart-manager-inc-button {
        width: 32px;
        border-radius: 50% !important;
        border-width: 0;
        background-color: #fff;
      }

      .instant-cart-manager-variant-big {
        // border-radius: 50% !important;
        display: inline-flex;
        flex-flow: row;
        float: right;
      }

      // .product-card .product-image-and-manager-container .instant-cart-manager {
      //     width: auto;
      //     left: 8px;
      // }
      .instant-cart-manager .instant-cart-manager-variant-big {
        height: 40px;
        padding: 8px;
        justify-content: flex-end;
      }

      .instant-cart-manager .instant-cart-manager-quantity {
        font-size: 14px;
        // font-weight: 700;
        text-align: center;
        width: calc(100% - 48px);
        color: var(--tertiary);
      }

      .instant-cart-manager-quantity {
        width: 50px !important;
        float: right;
      }
      .box-flex.instant-cart-manager.instant-cart-manager-variant-big.bg-white.cl-brand-primary.ai-center.jc-center.fd-row {
        float: right;
        margin-left: 10px;
        margin-top: 10px;
      }
      .box-flex.instant-cart-manager.instant-cart-manager-variant-big.bg-white.cl-brand-primary.ai-center.jc-end.fd-row {
        float: right;
        margin-top: 10px;
        margin-left: 10px;
      }
    `,
  ],
})
export class CartOneProductComponent implements OnInit {
  @Input() product: any;
  @Input() isDisabled: boolean;

  @Input() products: any[];
  @Input() addToCartList: any[];
  @Input() index: number;

  @Output() productRemoved = new EventEmitter<any>();

  @Output() productVariationRemoved = new EventEmitter<any>();
  @Output() productQty = new EventEmitter<any>();
  extraProductList: any[] = [];

  addToCart: AddToCart;
  productDtoList: any[] = [];
  unitInOrder: number;
  data: any;
  totalPrice: number;
  quantity: number;
  dataModel: DataModel;
  constructor(private token: TokenStorage,
    private changeDetectorRefs: ChangeDetectorRef,
  ) {
    this.addToCartList = [];
    if (
      this.token.getProductCart() != null ||
      this.token.getProductCart() != undefined
    ) {
      this.addToCartList = this.token.getProductCart();
    }
  }
  ngOnInit() {
    this.product.extraProductPrice = 0;
    this.changeDetectorRefs.detectChanges();
  }
  modelChangedProductPlus(product) {
    this.product.quantityProduct = this.product.quantityProduct + 1;

    if (
      this.product.discountedPrice == null ||
      this.product.discountedPrice == 0
    ) {
      this.product.totalPrice =
        this.product.quantityProduct * this.product.sellUnitPrice;
    } else {
      this.product.totalPrice =
        this.product.quantityProduct * this.product.discountedPrice;
    }

    if (this.product.quantityProduct === 0) {
      this.productRemoved.emit(this.product);
    }
  }
  modelChangedVariationPlus(product, i) {
    this.product.variationDtoList[i].quantityVariation =
      this.product.variationDtoList[i].quantityVariation + 1;

    if (
      this.product.variationDtoList[i].discountedPrice == null ||
      this.product.variationDtoList[i].discountedPrice == 0
    ) {
      this.product.variationDtoList[i].totalPrice =
        this.product.variationDtoList[i].quantityVariation *
        this.product.variationDtoList[i].sellUnitPrice;
    } else {
      this.product.variationDtoList[i].totalPrice =
        this.product.variationDtoList[i].quantityVariation *
        this.product.variationDtoList[i].discountedPrice;
    }

    if (this.product.variationDtoList[i].quantityVariation === 0) {
      this.productVariationRemoved.emit(this.product);
    }
  }


  modelChangedProductMinus(product) {
    this.product.quantityProduct = this.product.quantityProduct - 1;

    if (
      this.product.discountedPrice == null ||
      this.product.discountedPrice == 0
    ) {
      this.product.totalPrice =
        this.product.quantityProduct * this.product.sellUnitPrice;
    } else {
      this.product.totalPrice =
        this.product.quantityProduct * this.product.discountedPrice;
    }

    if (this.product.quantityProduct === 0) {
      this.productRemoved.emit(this.product);
    }
  }
  onChange(event: any, product, id, i) {
    this.token.clearADDToCart();
    if (event.target.checked == true) {
 
      this.product.extraProductPrice = this.product.extraProductPrice + Number(event.target.value);
      this.product.productDtoList.forEach(element => {
        if (element.id == id) {
          element.unitsInOrder = 1;
        }

      });
      this.product.toppingproductDtoList.forEach(element => {
        if (element.id == id) {
          element.unitsInOrder = 1;
        }

      });


      //   return value.isChecked
      // });
      // this.changeDetectorRefs.detectChanges();
    }
    else if (event.target.checked == false) {

      this.product.extraProductPrice = this.product.extraProductPrice - Number(event.target.value);


    }
  }
  modelChangedVariationMinus(product, i) {
    this.product.variationDtoList[i].quantityVariation =
      this.product.variationDtoList[i].quantityVariation - 1;

    if (
      this.product.variationDtoList[i].discountedPrice == null ||
      this.product.variationDtoList[i].discountedPrice == 0
    ) {
      this.product.variationDtoList[i].totalPrice =
        this.product.variationDtoList[i].quantityVariation *
        this.product.variationDtoList[i].sellUnitPrice;
    } else {
      this.product.variationDtoList[i].totalPrice =
        this.product.variationDtoList[i].quantityVariation *
        this.product.variationDtoList[i].discountedPrice;
    }

    if (this.product.variationDtoList[i].quantityVariation === 0) {
      this.productVariationRemoved.emit(this.product);
    }
  }


  addNoteToItems() {
    this.product.notes = "";
  }
  addNoteToItemsVariation(i) {
    this.product.variationDtoList[i].notes = "";
  }

  minusProductToCart(product) {
    product.this.modelChangedProductQty(product);
  }
  modelChangedProductQty(product) {
    this.productQty.emit(this.product);
  }
}