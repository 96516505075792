import { HotelBookingService } from 'src/app/services/hotel-booking.service';
import { Payment } from "src/app/model/payment";
import { DatePipe, Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { NgbCalendar, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { json } from "d3";
import { suburbList } from "src/app/data/cityList.data";
import { Address } from "src/app/model/address";
import { BankAccount } from "src/app/model/BankAccount";
import { BusinessOfferDto } from "src/app/model/businessOfferDto";
import { BusinessServiceDtoList } from "src/app/model/businessServiceDtoList";
import { Customer } from "src/app/model/customer";
import { DeliveryOption } from "src/app/model/deliveryOption";
import { MessageDto } from "src/app/model/MessageDto";
import { MobileWallet } from "src/app/model/mobileWallet";
import { Product } from "src/app/model/product";
import { productVariationDtoList } from "src/app/model/productVariation";
import { ServiceOpenDto } from "src/app/model/serviceOpenList";
import { BusinessUser } from "src/app/model/user";
import { ListingService } from "src/app/services/listing.service";
import { OrderService } from "src/app/services/order.service";
import { TokenStorage } from "src/app/token.storage";
import { Logger } from "../../services/logger.service";
import { ToastService } from "../../toast-global/toast-service";
import { OrderDto } from "../../model/orderDto";
import { TriggerEventService } from "src/app/trigger-event.service";
import { Slots } from "src/app/model/slots"; 
import { SlotReservation } from "src/app/model/slotReservation";
import { ResourceList } from "src/app/model/resourceList";
import { LocationList } from "src/app/model/locationList";
import { FormControl, Validators } from "@angular/forms";
import { BusinessServiceTypes } from "src/app/model/businessServiceTypes";
import { DateService } from "src/app/services/date-service.service";
import { KOT } from "src/app/model/KOT";
import { Available_Status, OutOfStock_Status } from "src/app/model/status";
import { Order } from "src/app/services/order";
import { Audit } from "src/app/services/audit";
import { AUDIT_ORDER_CREATE } from "src/app/app.component";
import { Booking } from "src/app/model/booking";

declare var Stripe: any;

declare var window: any;

export interface TimingArrayAndDetails {
  time: string;
  details: any;
}

export interface ProductGroupList {
  businessServiceId: number;
  productGroup: any[];
}
export interface BookingData {
  id: number;
  firstName: any;
  lastName: any;
  propertyReservationNumber: string;
  email: string;
  mobile: string;
  roomName: string;
  roomNumber: any;
  customerId: number;
  isGroupBooking: boolean;
  bookingOb: any;
}
@Component({
  selector: "app-orderOne",
  templateUrl: "./OrderOne.component.html",
  styleUrls: ["./OrderOne.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
})
export class OrderOneComponent implements OnInit {
  message: MessageDto;
  order: OrderDto;
  address: Address;
  addToCartList = [];
  submitButtonDisable: boolean = false;
  showAlert: boolean = false;
  alertType: string;
  kot: KOT;
  products: Product[];
  product: Product;
  productGroupsList: ProductGroupList[] = [];
  Available_Status: string = Available_Status;
  OutOfStock_Status: string = OutOfStock_Status;
  groupedOrderLines: any;
  bookingdata: BookingData[];
  // firstName: string;
  // lastName: string;
  // email: string;
  // phone: string;
  // verificationCode: string;

  verifyOption = "sms";
  // smsOption: string = '';
  sendBtn = "Send";

  loader = false;
  verificationCode: string;
  lookup = false;
  checkCustomerLookup = false;
  customerVerified = false;
  verificationSend = false;
  paymentLoader: boolean = false;
  verified = false;
  customerExist = false;
  verifiedPending: boolean = false;
  verifySuccessMessage: boolean = false;
  businessServiceType: BusinessServiceTypes;
  booking: Booking;
  RoomNo: string;
  roomDetails: any[];

  isSuccess: boolean;
  localityList: any = suburbList;
  suburbsLists: any;
  businessUser: BusinessUser;
  totalQuantity: number;
  totalPrice: number = 0;
  myDate: any;
  proVariationDtoLists: productVariationDtoList[];
  proVariationDtoList: productVariationDtoList;

  variationDtoLists: productVariationDtoList[];
  variationDtoList: productVariationDtoList;

  businessServiceDto: BusinessServiceDtoList;

  deliveryOption: DeliveryOption[];
  deliveryChargeOption: string;
  deliveryOptionObject: DeliveryOption;
  isTimeSlotAvailable: boolean;

  payment: Payment;
  prepareDay: number = 0;
  prepareHour: number = 0;
  prepareMinute: number = 0;

  headerTitle: string;
  bodyMessage: string;

  leadHour: number = 0;
  leadDay: number = 0;
  leadMin: number = 0;

  leadMaxDay: number = 0;
  leadMaxMin: number = 0;
  leadMaxHour: number = 0;
  pickfromstore = false;
  homeDelivery = false;
  cashPayment = false;

  mobileHasError: boolean = true;

  contentDialog: any;
  ngbDate: any;
  taxPercentage: number = 0;
  totalProductDiscount: number = 0;
  subTotalAmount: number = 0;
  totalAmount: number = 0;
  deliveryCharge: number = 0;

  orderDateUI: string;

  serviceOpenDto: ServiceOpenDto;
  propertyData: any;
  delivertDateAndTime: string;

  isWorkingTime: boolean = false;
  bookingSearchResult: string;
  private ewaySecureFieldCode: string;
  private ewayErrors: string = null;
  private ewayInitComplete: boolean = false;
  isVerified: boolean = false;

  bankAccount: BankAccount;
  mobileWallet: MobileWallet;
  discountPercentage: number;
  businessOfferDto: BusinessOfferDto[];
  promoCode: string;
  promoMessage = "";
  businessServiceId: number;
  currency: string;
  showHideFlag: boolean = false;
  customer: Customer;
  leadDayTotal: number = 0;
  leadHourTotal: number = 0;
  leadMinTotal: number = 0;
  leadMaxDayTotal: number;
  leadMaxHourTotal: number;
  leadMaxMinTotal: number;
  deliveryChargeAmount: number;
  cardPaymentAvailable: boolean;
  taxDetails: any[] = [];
  productDto: Product;
  productDtoList: Product[] = [];
  tempBusinessService:BusinessServiceDtoList;
  businesstaxarray:any[];
  taxDetailsArray: any[];
  slot: Slots;
  locationList: Slots[];
  resourceList: Slots[];
  slotTimingArrayList: any[];
  timesArray: any[];
  isNewOrderCreated: boolean;
  slotReservation: SlotReservation;
  resource: ResourceList;
  resources: ResourceList[];
  resourceSingleObject: ResourceList;
  locations: LocationList[];
  location: LocationList;
  TimeSlotDetails: any;
  orderSlotTime: any;
  isOrderIdAvailable: boolean;
  locationName: string;
  tableNumber: string;
  TimeSlot: FormControl = new FormControl("", [Validators.nullValidator]);
  Location: FormControl = new FormControl("", [Validators.required]);
  Resource: FormControl = new FormControl("", [Validators.required]);
  TableController: FormControl = new FormControl("", [Validators.required]);
  locationNameSelected: any;
  selectedResourceArray: any[] = [];
  selectedLocationArray: any[];
  resourceSelected: any[];
  AvailableSLotBusinessServiceTypes: BusinessServiceTypes[] = [];
  customerDetailsView: Boolean = false;
  kotList: KOT[] = [];
  taxPercentageDetails: any[];
  bookingFilter: any[] = [];
  bookings: Booking[] = [];
  paymentReservation: Payment;
  isShowBookingList: boolean = false;
  isCustomerInfoReadOnly: boolean = false;
  roomOrder: boolean = false;
  constructor(
    private token: TokenStorage,
    private triggerEventService: TriggerEventService,
    private ngZone: NgZone,
    private orderService: OrderService,
    private changeDetectorRefs: ChangeDetectorRef,
    private listingService: ListingService,
    private acRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private locationBack: Location,
    private datePipe: DatePipe,
    private toastService: ToastService,
    private calendar: NgbCalendar,
    private dateService: DateService,
    public datepipe: DatePipe,
    private hotelBookingService: HotelBookingService,
  ) {
    this.deliveryOption = [];
    this.message = new MessageDto();
    this.customer = new Customer();
    this.myDate = new Date();
    this.order = new OrderDto();
    this.address = new Address();
    this.businessServiceDto = new BusinessServiceDtoList();
    this.businessUser = new BusinessUser();
    this.payment = new Payment();
    this.businessOfferDto = [];
    this.mobileWallet = new MobileWallet();
    this.bankAccount = new BankAccount();
    this.taxDetailsArray =[];
    this.slotReservation = new SlotReservation();
    this.resource = new ResourceList();
    this.location = new LocationList();
    this.order.resourceName = this.tableNumber;
    // this.acRoute.queryParams.subscribe((params) => {
    //   if (params["orderData"] !== undefined) {
    //     this.order = JSON.parse(params["orderData"]);
    //     this.businessServiceId = Number(JSON.parse(params["serviceId"]));
    //     this.addToCartList = this.token.getProductCart();
    //     Logger.log("this.addToCartList:" + JSON.stringify(this.addToCartList));
    //     Logger.log("this.order:" + JSON.stringify(this.order));

    //     this.getPropertyDetails(this.order.propertyId);
    //   }
    // });
    this.order = this.token.getOrderCart();
    this.businessServiceId = Number(this.token.getServiceId());
    this.getPropertyDetails(this.order.propertyId);
    this.addToCartList = this.token.getProductCart();
    this.kot = new KOT();
    this.mobileWallet = this.businessUser.mobileWallet;
    this.bankAccount = this.businessUser.bankAccount;
    // if (this.order.email == undefined) {
    //   this.order.email = "";
    // }

    // if (this.order.mobile == undefined) {
    //   this.order.mobile = "";
    // }

    this.address.city = "";
    this.address.suburb = "";
    this.address.streetName = "";
    this.address.streetNumber = "";
    this.suburbsLists = this.localityList.find(
      (data) => data.city === this.address.city
    );
    this.payment.expYear = "";
    this.payment.expMonth = "";

    this.addPropertyDataFromAddtoCart();
    this.order.deliveryChargeAmount = this.deliveryCharge;
    this.payment.deliveryChargeAmount = this.deliveryCharge;
    this.slot = new Slots();
   
    this.getAllDeliveryOptionByServiceId(this.businessServiceId);
    
    // this.setOffering(this.order.businessServiceTypeId);
    this.getAllBusinessService(this.order.propertyId);
    this.AvailableSLotBusinessServiceTypes = [];
    this.taxPercentageDetails = [];
  
  }

  ngOnInit() {
    setTimeout(() => (this.showAlert = false), 5000);
    this.address.state = "";
    this.address.country = "India";
  
   
    if (this.order.deliveryMethod = "Dine In"){
      this.getResourceData(this.businessServiceId);
    this.getLocationData(this.businessServiceId);
    this.orderCurrentDate();
    this.kotList = [];
            if (this.order.kotDtoList != null && this.order.kotDtoList.length > 0) {
                this.kotList = this.order.kotDtoList;
            }

    }
    this.getResourceData(this.businessServiceId);
    this.getOrderDateAndTime();

    window["angularComponentReference"] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: () => this.stripePaymentSuccess(),
    };
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }
    });

    if(this.order.deliveryMethod === "Dine In"){
      this.dineIn();
    }
  }


  viewCustomerDetails(){
    this.customerDetailsView = true;
  }
  getPropertyDetails(id: number) {
    this.loader = true;
    this.listingService.findByPropertyId(id).subscribe(
      (data) => {
        this.businessUser = data.body;

        if (
          this.businessUser.localCurrency != null &&
          this.businessUser.localCurrency != undefined
        ) {
          this.currency = this.businessUser.localCurrency.toUpperCase();
        }

        this.order.businessServiceId = this.businessServiceId;
        // this.setOffering(this.order.businessServiceTypeId);
       
        
        this.getAllDeliveryOptionByServiceId(this.businessServiceId);
        this.mobileWallet = this.businessUser.mobileWallet;
        this.bankAccount = this.businessUser.bankAccount;
        // this.getOfferDetails();

        this.businessServiceDto = this.businessUser.businessServiceDtoList.find(
          (data) => data.id === this.businessServiceId
        );

          const businessServiceTypesId = this.businessServiceDto.businessServiceTypes.find((businessServiceType) => businessServiceType.id).id;
      
          this.order.businessServiceTypeId = businessServiceTypesId;
       

        if (
          this.businessServiceDto.maxLeadTime != undefined ||
          this.businessServiceDto.maxLeadTime == 0
        ) {
          let maxLead = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.maxLeadTime,
            0
          );

          this.leadMaxDay = Math.floor(
            this.businessServiceDto.maxLeadTime / 1440
          );
          this.leadMaxDayTotal = this.leadMaxDay;
          this.leadMaxMin = maxLead.getMinutes();
          this.leadMaxMinTotal = this.leadMaxMin;
          this.leadMaxHour = maxLead.getHours();
          this.leadMaxHourTotal = this.leadMaxHour;
        }

        if (
          this.businessServiceDto.minLeadTime != undefined ||
          this.businessServiceDto.minLeadTime == 0
        ) {
          let minLead = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.minLeadTime,
            0
          );

          this.leadHour = minLead.getHours();
          this.leadHourTotal = this.leadHour;
          this.leadDay = Math.floor(this.businessServiceDto.minLeadTime / 1440);
          this.leadDayTotal = this.leadDay;
          this.leadMin = minLead.getMinutes();
          this.leadMinTotal = this.leadMin;
        }

        if (
          this.businessServiceDto.stdPrepTime != undefined ||
          this.businessServiceDto.stdPrepTime == 0
        ) {
          var prep = new Date(
            0,
            0,
            0,
            0,
            this.businessServiceDto.stdPrepTime,
            0
          );

          this.prepareDay = Math.floor(
            this.businessServiceDto.maxLeadTime / 1440
          );
          this.prepareHour = prep.getHours();
          this.prepareMinute = prep.getMinutes();
        }
        // tax slab select
        this.taxPercentage = this.calculateTax();
       

        this.subTotalAmount = this.calculatePrice();
        this.calculateProductDistount();
        this.order.netAmount = this.subTotalAmount;
        if (
          this.discountPercentage !== null &&
          this.discountPercentage !== undefined
        ) {
          this.order.discountAmount =
            (this.order.netAmount * this.discountPercentage) / 100;
        } else {
          this.order.discountAmount = 0;
        }
        if(this.taxPercentage != null){
          this.order.taxAmount =
          this.order.netAmount * (this.taxPercentage / 100);
        } else {
          this.order.taxAmount = 0;
        }
       
        if (this.homeDelivery === true) {
          this.order.totalOrderAmount =
            this.order.netAmount +
            this.order.taxAmount +
            this.order.deliveryChargeAmount -
            this.order.discountAmount;
            
        } else  {
          this.order.totalOrderAmount =
            this.order.netAmount +
            this.order.taxAmount -
            this.order.discountAmount;
            
        }

        this.totalAmount = this.order.totalOrderAmount;
        
        this.totalAmount = Math.round(this.totalAmount);
        this.order.totalOrderAmount = Math.round(this.totalAmount);
        
        Logger.log("order " + JSON.stringify(this.order));
        this.loader = false;
        this.changeDetectorRefs.detectChanges();

        this.claculateDeliveryTime(this.businessServiceDto);
      },
      (_error) => {
        this.loader = false;
      }
    );
  }

  addPropertyDataFromAddtoCart() {
    this.products = [];
    this.variationDtoLists = [];

    for (let i = 0; i < this.addToCartList.length; i++) {
      this.product = new Product();
      this.product.id = this.addToCartList[i].id;
      this.product.name = this.addToCartList[i].name;
      this.product.productCode = this.addToCartList[i].productCode;
      this.product.shortDescription = this.addToCartList[i].shortDescription;
      this.product.description = this.addToCartList[i].description;
      this.product.sellUnitPrice = this.addToCartList[i].sellUnitPrice;
      this.product.productGroupId = this.addToCartList[i].productGroupId;
      this.product.discountedPrice = this.addToCartList[i].discountedPrice;
      this.product.unitsInOrder = this.addToCartList[i].quantityProduct;
      this.product.totalPrice = this.addToCartList[i].totalPrice;
      this.product.notes = this.addToCartList[i].notes;
      
    
      this.variationDtoLists = [];

      if (
        this.addToCartList[i].variationDtoList != undefined ||
        this.addToCartList[i].variationDtoList != null
      ) {
        for (
          let j = 0;
          j < this.addToCartList[i].variationDtoList.length;
          j++
        ) {
          this.variationDtoList = new productVariationDtoList();

          this.variationDtoList.buyUnitPrice =
            this.addToCartList[i].variationDtoList[j].buyUnitPrice;
          this.variationDtoList.code =
            this.addToCartList[i].variationDtoList[j].code;
          this.variationDtoList.id =
            this.addToCartList[i].variationDtoList[j].id;
          this.variationDtoList.discountedPrice =
            this.addToCartList[i].variationDtoList[j].discountedPrice;
          this.variationDtoList.notes =
            this.addToCartList[i].variationDtoList[j].notes;
          this.variationDtoList.name =
            this.addToCartList[i].variationDtoList[j].name;
          this.variationDtoList.productId =
            this.addToCartList[i].variationDtoList[j].productId;
          this.variationDtoList.sellUnitPrice =
            this.addToCartList[i].variationDtoList[j].sellUnitPrice;
          this.variationDtoList.unitsInOrder =
            this.addToCartList[i].variationDtoList[j].quantityVariation;
          this.variationDtoList.unitsInStock =
            this.addToCartList[i].variationDtoList[j].unitsInStock;

          this.variationDtoList.quantityVariation =
            this.addToCartList[i].variationDtoList[j].quantityVariation;
          this.variationDtoList.totalPrice =
            this.addToCartList[i].variationDtoList[j].totalPrice;

          this.variationDtoLists.push(this.variationDtoList);
        }
      }

      // this.product.productVariationDtoList = this.addToCartList[i].variationDtoList;
      this.product.productVariationDtoList = this.variationDtoLists;

      this.products.push(this.product);
      const productGroup: ProductGroupList = {
        businessServiceId: this.businessServiceId,
        productGroup: this.addToCartList,
    };
    
    this.productGroupsList.push(productGroup);
      if (
        this.addToCartList[i].productDtoList != undefined ||
        this.addToCartList[i].productDtoList != null
      ){
      for (
        let h = 0;
        h < this.addToCartList[i].productDtoList.length;
        h++
      ) {
        
if(this.addToCartList[i].productDtoList[h].unitsInOrder ==1){
  this.productDto = new Product();
  this.productDto.buyUnitPrice =
          this.addToCartList[i].productDtoList[h].buyUnitPrice;
        this.productDto.productCode =
          this.addToCartList[i].productDtoList[h].productCode;
        this.productDto.id =
          this.addToCartList[i].productDtoList[h].id;
        this.productDto.discountedPrice =
          this.addToCartList[i].productDtoList[h].discountedPrice;
        this.productDto.notes =
          this.addToCartList[i].productDtoList[h].notes;
        this.productDto.name =
          this.addToCartList[i].productDtoList[h].name;
        this.productDto.sellUnitPrice =
          this.addToCartList[i].productDtoList[h].sellUnitPrice;
        this.productDto.unitsInOrder =
          this.addToCartList[i].productDtoList[h].unitsInOrder;
        
        this.productDto.totalPrice =
          this.addToCartList[i].productDtoList[h].sellUnitPrice;

        this.products.push(this.productDto);
}

        
      }
    }
    if (
      this.addToCartList[i].toppingproductDtoList != undefined ||
      this.addToCartList[i].toppingproductDtoList != null
    ){
      for (
        let l = 0;
        l < this.addToCartList[i].toppingproductDtoList.length;
        l++
      ) {
        
        if(this.addToCartList[i].toppingproductDtoList[l].unitsInOrder ==1){
          this.productDto = new Product();
          this.productDto.buyUnitPrice =
                  this.addToCartList[i].toppingproductDtoList[l].buyUnitPrice;
                this.productDto.productCode =
                  this.addToCartList[i].toppingproductDtoList[l].productCode;
                this.productDto.id =
                  this.addToCartList[i].toppingproductDtoList[l].id;
                this.productDto.discountedPrice =
                  this.addToCartList[i].toppingproductDtoList[l].discountedPrice;
                this.productDto.notes =
                  this.addToCartList[i].toppingproductDtoList[l].notes;
                this.productDto.name =
                  this.addToCartList[i].toppingproductDtoList[l].name;
                this.productDto.sellUnitPrice =
                  this.addToCartList[i].toppingproductDtoList[l].sellUnitPrice;
                this.productDto.unitsInOrder =
                  this.addToCartList[i].toppingproductDtoList[l].unitsInOrder;
                
                this.productDto.totalPrice =
                  this.addToCartList[i].toppingproductDtoList[l].sellUnitPrice;
        
                this.products.push(this.productDto);
        }

        
      }

    
      
    }
  }
  

    this.order.productDtoList = this.products;
  }

  
  getAllBusinessService(id: number) {
    //  this.businesstaxarray = new this.businesstaxarray();
    this.listingService.findByPropertyId(id).subscribe(
      (data) => {
        this.businessUser = data.body;
       
        if (
          this.businessUser.localCurrency != null &&
          this.businessUser.localCurrency != undefined
        ) {
          this.currency = this.businessUser.localCurrency.toUpperCase();
        }

        this.order.businessServiceId = this.businessServiceId;
        this.setOffering(this.order.businessServiceTypeId);
       
        
        this.getAllDeliveryOptionByServiceId(this.businessServiceId);
        this.mobileWallet = this.businessUser.mobileWallet;
        this.bankAccount = this.businessUser.bankAccount;
    this.businessServiceDto = this.businessUser.businessServiceDtoList.find(
      (data) => data.id === this.businessServiceId
    );
    
    this.businesstaxarray = [];
    this.businessServiceDto.taxDetails.forEach(element => {
      if (element.name === 'CGST') {
        this.taxDetailsArray.push(element)
        element.taxAmount = this.order.taxAmount/2;
      
        this.businesstaxarray.push(element)
      }if (element.name === 'SGST') {
        this.taxDetailsArray.push(element)
        element.taxAmount = this.order.taxAmount/2;
       
        this.businesstaxarray.push(element)
      } 
   
    });

    },
    (_error) => {
      this.loader = false;
    }
  );
    }
  getSlotByDate(slot: Slots, serviceTypeId: string) {

    this.loader = true;

    this.orderService.getSlotDataByDate(slot, serviceTypeId).subscribe(
      (data) => {
        this.slot = data.body;

        this.slotTimingArrayList = [];
        this.timesArray = [];

        if (
          this.slot.resourceList != null &&
          this.slot.resourceList != undefined &&
          this.slot.resourceList.length > 0
        ) {
          for (let i = 0; i < this.slot.resourceList.length; i++) {
            if (
              this.slot.resourceList[i].availableTimings != null &&
              this.slot.resourceList[i].availableTimings != undefined &&
              this.slot.resourceList[i].availableTimings.length > 0
            ) {
              for (
                let j = 0;
                j < this.slot.resourceList[i].availableTimings.length;
                j++
              ) {
                if (
                  this.slotTimingArrayList.indexOf(
                    this.slot.resourceList[i].availableTimings[j].startTime +
                      "-" +
                      this.slot.resourceList[i].availableTimings[j].finishTime
                  ) == -1
                ) {
                  this.slotTimingArrayList.push(
                    this.slot.resourceList[i].availableTimings[j].startTime +
                      "-" +
                      this.slot.resourceList[i].availableTimings[j].finishTime
                  );

                  const timeSLot: TimingArrayAndDetails = {
                    time:
                      this.slot.resourceList[i].availableTimings[j].startTime +
                      "-" +
                      this.slot.resourceList[i].availableTimings[j].finishTime,
                    details: this.slot.resourceList[i].availableTimings[j],
                  };

                  this.timesArray.push(timeSLot);
                }
              }
            }
          }
        }

        if (this.isNewOrderCreated === false) {
          if (
            this.order.businessReservationNumber != null &&
            this.order.businessReservationNumber != undefined &&
            this.businessUser != undefined &&
            this.businessUser != null &&
            this.businessUser.serviceReservation != null &&
            this.businessUser.serviceReservation != undefined &&
            this.businessUser.serviceReservation === true
          ) {
            if (
              this.slotReservation.slotReservationDtos != null &&
              this.slotReservation.slotReservationDtos != undefined &&
              this.slotReservation.slotReservationDtos.length > 0
            ) {
              for (let i = 0; i < this.timesArray.length; i++) {
                if (
                  this.timesArray[i].time ===
                  this.slotReservation.slotReservationDtos[0].startTime +
                    "-" +
                    this.slotReservation.slotReservationDtos[0].finishTime
                ) {
                  this.TimeSlotDetails = this.timesArray[i];
                  this.setTimeSlotForReservation(this.TimeSlotDetails);
                }
              }
            }
            Logger.log("service reservation");
          } else if (
            this.orderSlotTime != null &&
            this.orderSlotTime != undefined
          ) {
            for (let i = 0; i < this.timesArray.length; i++) {
              if (this.timesArray[i].time === this.orderSlotTime) {
                this.TimeSlotDetails = this.timesArray[i];
                this.setTimeSlotForOrder(this.TimeSlotDetails);
              }
            }

            Logger.log("service order");
          } else if (this.isOrderIdAvailable === false) {
            for (let i = 0; i < this.timesArray.length; i++) {
              this.timeCheckDyneIn(this.timesArray[i].time, this.timesArray[i]);
            }
          }
        } else {
          for (let i = 0; i < this.timesArray.length; i++) {
            this.timeCheck(this.timesArray[i].time, this.timesArray[i]);
          }
        }

        this.loader = false;
        // this.UIDetectChange();
      },
      (error) => {
        this.loader = false;
        Logger.log("all service" + JSON.stringify(error));
      }
    );
  }

  timeCheck(timeIntervalString: string, timeArrayDetails: any) {
    let isTimeChecked: boolean = false;
    let startTime = timeIntervalString.split("-")[0];
    let endTime = timeIntervalString.split("-")[1];

    let currentDate = new Date();

    //currentDate.setHours(1);

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(Number(startTime.split(":")[0]));
    startDate.setMinutes(Number(startTime.split(":")[1]));
    startDate.setSeconds(0);

    let endDate = new Date(currentDate.getTime());
    endDate.setHours(Number(endTime.split(":")[0]));
    endDate.setMinutes(Number(endTime.split(":")[1]));
    endDate.setSeconds(0);

    let valid = startDate < currentDate && endDate > currentDate;

    if (valid === true) {
      this.setTimeSlot(timeArrayDetails);
      this.isTimeSlotAvailable = true;
      this.order.orderedTime = timeArrayDetails.time;
    }
  }

  setTimeSlot(data) {
    this.selectedResourceArray = [];
    this.selectedLocationArray = [];
    this.TimeSlotDetails = data;

    // this.resource.bookedTimings = [];
    // this.resource.bookedTimings.push(this.TimeSlotDetails.details);

    this.order.orderedTime = this.TimeSlotDetails.time;
    this.orderSlotTime = this.order.orderedTime;

    for (let i = 0; i < this.slot.resourceList.length; i++) {
      if (
        this.slot.resourceList[i].availableTimings != null &&
        this.slot.resourceList[i].availableTimings != undefined &&
        this.slot.resourceList[i].availableTimings.length > 0
      ) {
        for (
          let j = 0;
          j < this.slot.resourceList[i].availableTimings.length;
          j++
        ) {
          if (
            this.TimeSlotDetails.time ===
            this.slot.resourceList[i].availableTimings[j].startTime +
              "-" +
              this.slot.resourceList[i].availableTimings[j].finishTime
          ) {
            this.selectedResourceArray.push(this.slot.resourceList[i]);

            for (
              let k = 0;
              k < this.slot.resourceList[i].locationList.length;
              k++
            ) {
              if (
                this.selectedLocationArray.indexOf(
                  this.slot.resourceList[i].locationList[k].name
                )
              ) {
                this.selectedLocationArray.push(
                  this.slot.resourceList[i].locationList[k].name
                );
              }
            }
          }
        }
      }
    }

    this.Resource.reset();
    this.Location.reset();

    if (
      this.isOrderIdAvailable === false &&
      this.order.resourceName != undefined &&
      this.order.resourceName != null &&
      this.order.locationName != undefined &&
      this.order.locationName != null 
    ) {
      this.locationNameSelected = this.order.locationName.split(",");

      this.locations = [];
      for (let i = 0; i < this.locationNameSelected.length; i++) {
        this.location = new LocationList();
        this.location.name = this.locationNameSelected[i];
        this.locations.push(this.location);
      }

      this.resourceSelected = [];

      let resourceNameList = this.order.resourceName.split(",");

      for (let i = 0; i < this.selectedResourceArray.length; i++) {
        for (let j = 0; j < resourceNameList.length; j++) {
          if (this.selectedResourceArray[i].name === resourceNameList[j]) {
            this.resourceSelected.push(this.selectedResourceArray[i]);
          }
        }
      }

      this.setResource(this.resourceSelected);
    }
  }

  setResource(data) {
    Logger.log("data " + JSON.stringify(data));

    if (Array.isArray(data)) {
      this.resourceSelected = data;
      this.resources = [];
      let resourceNameList = [];

      for (let i = 0; i < this.resourceSelected.length; i++) {
        this.resourceSingleObject = new ResourceList();

        resourceNameList.push(this.resourceSelected[i].name);

        this.resourceSingleObject.bookedTimings = [];
        this.resourceSingleObject.bookedTimings.push(
          this.TimeSlotDetails.details
        );

        this.resourceSingleObject.locationList = [];
        this.resourceSingleObject.locationList = this.locations;

        this.resourceSingleObject.name = this.resourceSelected[i].name;

        this.resources.push(this.resourceSingleObject);
      }
      this.order.resourceName = resourceNameList.toString();
    } else {
      this.order.resourceName = data.name;
    }

    Logger.log(
      "this.order.resourceName " + JSON.stringify(this.order.resourceName)
    );

    this.businessServiceType.slots = [];

    this.slot.date = this.order.orderedDate;
    this.slot.resourceList = [];
    // this.slot.resourceList.push(this.resource);
    this.slot.resourceList = this.resources;

    this.businessServiceType.slots.push(this.slot);

    this.slotReservation.businessServiceTypes = [];
    this.slotReservation.businessServiceTypes.push(this.businessServiceType);
  }
  timeCheckDyneIn(timeIntervalString: string, timeArrayDetails: any) {
    let isTimeChecked: boolean = false;
    let startTime = timeIntervalString.split("-")[0];
    let endTime = timeIntervalString.split("-")[1];

    let currentDate = new Date();

    //currentDate.setHours(1);

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(Number(startTime.split(":")[0]));
    startDate.setMinutes(Number(startTime.split(":")[1]));
    startDate.setSeconds(0);

    let endDate = new Date(currentDate.getTime());
    endDate.setHours(Number(endTime.split(":")[0]));
    endDate.setMinutes(Number(endTime.split(":")[1]));
    endDate.setSeconds(0);

    let valid = startDate < currentDate && endDate > currentDate;

    if (valid === true) {
      this.setTimeSlot(timeArrayDetails);
      this.isTimeSlotAvailable = true;
      this.order.orderedTime = timeArrayDetails.time;
    }
  }

  setTimeSlotForOrder(data) {
    this.selectedResourceArray = [];
    this.selectedLocationArray = [];
    this.TimeSlotDetails = data;

    this.resource.bookedTimings = [];
    this.resource.bookedTimings.push(this.TimeSlotDetails.details);

    this.order.orderedTime = this.TimeSlotDetails.time;

    for (let i = 0; i < this.slot.resourceList.length; i++) {
      if (
        this.slot.resourceList[i].availableTimings != null &&
        this.slot.resourceList[i].availableTimings != undefined &&
        this.slot.resourceList[i].availableTimings.length > 0
      ) {
        for (
          let j = 0;
          j < this.slot.resourceList[i].availableTimings.length;
          j++
        ) {
          if (
            this.TimeSlotDetails.time ===
            this.slot.resourceList[i].availableTimings[j].startTime +
              "-" +
              this.slot.resourceList[i].availableTimings[j].finishTime
          ) {
            this.selectedResourceArray.push(this.slot.resourceList[i]);

            for (
              let k = 0;
              k < this.slot.resourceList[i].locationList.length;
              k++
            ) {
              if (
                this.selectedLocationArray.indexOf(
                  this.slot.resourceList[i].locationList[k].name
                )
              ) {
                this.selectedLocationArray.push(
                  this.slot.resourceList[i].locationList[k].name
                );
              }
            }
          }
        }
      }
    }

    if (
      this.order.locationName != null &&
      this.order.locationName != undefined 
    ) {
      this.locationNameSelected = this.order.locationName.split(",");

      this.locations = [];
      for (let i = 0; i < this.locationNameSelected.length; i++) {
        this.location = new LocationList();
        this.location.name = this.locationNameSelected[i];
        this.locations.push(this.location);
      }
    }

    if (
      this.order.resourceName != null &&
      this.order.resourceName != undefined
    ) {
      this.resourceSelected = [];

      let resourceNameList = this.order.resourceName.split(",");

      for (let i = 0; i < this.selectedResourceArray.length; i++) {
        for (let j = 0; j < resourceNameList.length; j++) {
          if (this.selectedResourceArray[i].name === resourceNameList[j]) {
            this.resourceSelected.push(this.selectedResourceArray[i]);
          }
        }
      }

      this.setResource(this.resourceSelected);
    }
  }

  setTimeSlotForReservation(data) {
    this.selectedResourceArray = [];
    this.selectedLocationArray = [];
    this.TimeSlotDetails = data;

    this.resource.bookedTimings = [];
    this.resource.bookedTimings.push(this.TimeSlotDetails.details);

    this.order.orderedTime = this.TimeSlotDetails.time;

    for (let i = 0; i < this.slot.resourceList.length; i++) {
      if (
        this.slot.resourceList[i].availableTimings != null &&
        this.slot.resourceList[i].availableTimings != undefined &&
        this.slot.resourceList[i].availableTimings.length > 0
      ) {
        for (
          let j = 0;
          j < this.slot.resourceList[i].availableTimings.length;
          j++
        ) {
          if (
            this.TimeSlotDetails.time ===
            this.slot.resourceList[i].availableTimings[j].startTime +
              "-" +
              this.slot.resourceList[i].availableTimings[j].finishTime
          ) {
            this.selectedResourceArray.push(this.slot.resourceList[i]);

            for (
              let k = 0;
              k < this.slot.resourceList[i].locationList.length;
              k++
            ) {
              if (
                this.selectedLocationArray.indexOf(
                  this.slot.resourceList[i].locationList[k].name
                )
              ) {
                this.selectedLocationArray.push(
                  this.slot.resourceList[i].locationList[k].name
                );
              }
            }
          }
        }
      }
    }

    this.locationNameSelected =
      this.slotReservation.slotReservationDtos[0].locationName.split(",");

    this.locations = [];
    for (let i = 0; i < this.locationNameSelected.length; i++) {
      this.location = new LocationList();
      this.location.name = this.locationNameSelected[i];
      this.locations.push(this.location);
    }

    this.resourceSelected = [];

    for (let i = 0; i < this.selectedResourceArray.length; i++) {
      for (
        let j = 0;
        j < this.slotReservation.slotReservationDtos.length;
        j++
      ) {
        if (
          this.selectedResourceArray[i].name ===
          this.slotReservation.slotReservationDtos[j].resourceName
        ) {
          this.resourceSelected.push(this.selectedResourceArray[i]);
        }
      }
    }

    this.setResource(this.resourceSelected);
  }

  generateKot(orderLines) {
    
    // Filter orderLines for those with groupKot set to true
    const orderLinesToGroup = orderLines.filter(line => line.groupKot);

    // Group those orderLines by productGroupId
    this.groupedOrderLines = this.groupByProductGroupId(orderLinesToGroup);
  
    this.createKot(this.groupedOrderLines, orderLines.filter(line => !line.groupKot));
  }

  createKot(groupedOrderLines, individualOrderLines) {
 
    const currentDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    // Create KOTs for grouped orderLines
    let kotListToBeCreate = [];
    for (const productGroupId in groupedOrderLines) {
     
      if (groupedOrderLines.hasOwnProperty(productGroupId)) {
        const orderLineDtoList = groupedOrderLines[productGroupId];
        let printerName = null;
        let kot = new KOT();
        kot.date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
        kot.operatorName = this.order.operatorName;
        kot.propertyId = this.order.propertyId;
        kot.tableNo = this.order.resourceName;
        kot.time = this.order.requiredTime;
        kot.productGroupName = orderLineDtoList[0].productGroupName;
        kot.orderLines = orderLineDtoList;
        kot.orderNo = this.order.bookOneOrderId;
        kot.orderType = this.order.deliveryMethod;
        kot.priority = this.kotList.length + 1;
        kot.printerName = printerName;
        kotListToBeCreate.push(kot);
      }
    
    }

    this.orderService.createKots(kotListToBeCreate).subscribe(
      (data) => {
        // Handle success
      },
      (error) => {
        this.loader = false;
        // Handle error
      }
    );

    if (individualOrderLines.length > 0) {
        this.createOrderKot(individualOrderLines);
      
      
    }
    
  
  }

  groupByProductGroupId(orderLines: any[]): any {
    return orderLines.reduce((groupedOrderLines, orderLine) => {
      const groupId = orderLine.productGroupId;
      if (!groupedOrderLines[groupId]) {
        groupedOrderLines[groupId] = [];
      }
      groupedOrderLines[groupId].push(orderLine);
      return groupedOrderLines;
    }, {});
  }

  createOrderKot(orderLineDtoList) {
    let printerName = null;
    this.kot.date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.kot.operatorName = '';
    this.kot.propertyId = this.order.propertyId;
    this.kot.tableNo = this.order.resourceName;
    this.kot.time = this.order.requiredTime;
    this.kot.orderLines = orderLineDtoList;
    this.kot.orderNo = this.order.bookOneOrderId;
    this.kot.orderType = this.order.deliveryMethod;
    this.kot.priority = this.kotList.length +1;
    this.kot.printerName = printerName;
    this.orderService.createKot(this.kot).subscribe(
      (data) => {
      },
      (error) => {
        this.loader = false;
      }
    );
  }

  checkKotUnitInOrder(item) {
    let UnitInOrder = 0;
    for (let i = 0; i < this.kotList.length; i++) {
      for (let l = 0; l < this.kotList[i].orderLines.length; l++) {
        if (
          this.kotList[i].orderLines[l].name === item.name &&
          this.kotList[i].orderLines[l].productCode === item.productCode &&
          this.checkStatus(this.kotList[i].orderLines[l].status) != Available_Status
        ) {
          UnitInOrder =
            UnitInOrder + this.kotList[i].orderLines[l].unitsInOrder;
        }
      }
    }

    return UnitInOrder;
  }

  checkKotUnitInOrderForVariation(item) {
    let UnitInOrder = 0;
    for (let i = 0; i < this.kotList.length; i++) {
      for (let l = 0; l < this.kotList[i].orderLines.length; l++) {
        if (
          this.kotList[i].orderLines[l].name === item.name &&
          this.kotList[i].orderLines[l].productCode === item.code &&
          this.checkStatus(this.kotList[i].orderLines[l].status) != Available_Status
        ) {
          UnitInOrder =
            UnitInOrder + this.kotList[i].orderLines[l].unitsInOrder;
        }
      }
    }

    return UnitInOrder;
  }

  checkStatus(status) {
    if (status === null || status === undefined || status === "") {
      return "Available";
    }

    return status;
  }

  getKotItemInOrder(item) {
    let itemList = []
    for (let i = 0; i < this.kotList.length; i++) {
      for (let l = 0; l < this.kotList[i].orderLines.length; l++) {
        if (
          this.kotList[i].orderLines[l].name === item.name &&
          this.kotList[i].orderLines[l].productCode === item.productCode
        ) {
          itemList.push(this.kotList[i].orderLines[l]);
        }
      }
    }

    return itemList;
  }

  getKotItemInOrderVariation(item) {
    let itemList = []
    for (let i = 0; i < this.kotList.length; i++) {
      for (let l = 0; l < this.kotList[i].orderLines.length; l++) {
        if (
          this.kotList[i].orderLines[l].name === item.name &&
          this.kotList[i].orderLines[l].productCode === item.code
        ) {
          itemList.push(this.kotList[i].orderLines[l]);
        }
      }
    }

    return itemList;
  }

  orderCurrentDate() {
    let currentDate: Date = new Date();

    //   const fromDateMilliSeconds = currentDate.getTime();

    let daySelected = this.getDay(currentDate);
    let yearSelected = String(currentDate.getFullYear());
    let monthSelected = this.getMonth(currentDate.getMonth() + 1);

    // this.order.requiredDate =
    //   yearSelected + "-" + monthSelected + "-" + daySelected;
    this.order.orderedDate =
        yearSelected + "-" + monthSelected + "-" + daySelected;
    this.slot.date = this.order.orderedDate;
    this.slotReservation.date = this.order.orderedDate;

    if (this.order.deliveryMethod != "Dine In") {
        this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
    }
}

getDay(date: Date) {
  let currentDay;
  if (date.getDate().toString().length == 1) {
      currentDay = "0" + date.getDate();
  } else {
      currentDay = "" + date.getDate();
  }

  return currentDay;
}

getMonth(date: number) {
  let currentMonth;
  if (date.toString().length == 1) {
      currentMonth = "0" + date;
  } else {
      currentMonth = "" + date;
  }

  return currentMonth;
}

  setOffering(serviceTypeId: number) {

    this.businessServiceType = this.AvailableSLotBusinessServiceTypes.find(
      (data) => data.id === serviceTypeId
    );

    this.order.businessServiceTypeId = serviceTypeId;
    this.slot.businessServiceTypeId = serviceTypeId;
    this.slot.date = this.dateService.convertMillisecondsToYYYMMDDFormat(
      this.order.orderedDate
    );

    this.slotTimingArrayList = [];
    this.timesArray = [];

    this.getSlotByDate(this.slot, String(this.slot.businessServiceTypeId));

    this.Resource.reset();
    this.Location.reset();
    this.TimeSlot.reset();
  
  }

  getLocationData(serviceId) {
    this.orderService.getAllLocations(serviceId).subscribe((data) => {
      this.locationList = [];
      this.locationList = data.body;
      if(this.locationList.length> 0){
        
        if (this.order.deliveryMethod = "Dine In"){
          this.locationName = this.locationList[0].locationName;
      this.order.locationName = this.locationList[0].locationName;
        }
      }
      //  if (
      //   this.token.getOrderTypeInfo() !== null &&
      //   this.token.getOrderTypeInfo() !== undefined
      // ) {
      //   this.locationName = this.order.locationName;
      // }
      this.changeDetectorRefs.detectChanges();
    });
  }

  getResourceData(serviceId) {
    this.orderService.getAllResources(serviceId).subscribe((data) => {
      this.resourceList = data.body;
     
      // if (
      //   this.token.getOrderTypeInfo() !== null &&
      //   this.token.getOrderTypeInfo() !== undefined
      // ) {
      //   if(this.token.getOrderTypeInfo().orderType !== null){
      //     this.order.deliveryMethod = this.token.getOrderTypeInfo().orderType;
      //   }else{
      //     this.order.deliveryMethod = "Pick From Store";
      //   }
      //   if(this.token.getOrderTypeInfo().resourceName !== null){
      //   this.order.resourceName = this.token.getOrderTypeInfo().resourceName;
      //   this.tableNumber = this.order.resourceName;
      //   }
        
      // }

      this.changeDetectorRefs.detectChanges();
    });
  }

  selectTable(resourceName: string) {
    this.tableNumber = resourceName;
    this.order.resourceName = this.tableNumber;
    
  }

  getOrderDateAndTime() {
    const currentDate: Date = new Date();
    this.order.orderedDate = this.getDateDBFormat(currentDate);
    this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
  }
  upiPayment() {
    this.getOrderDateAndTime();
    this.order.shipToAddress = this.address;
    this.order.orderStatus = "Submitted";
    this.payment.paymentMode = "UPI";
    // this.payment.status = 'NotPaid';
    // this.payment.email = this.token.getCustomer().email;
    // this.payment.paymentMode = 'Cash';
    this.payment.status = "NotPaid";
    this.payment.businessServiceName = "Restaurants";
    // this.payment.paymentMode = this.order.modeOfPayment;
    this.payment.firstName = this.order.firstName;
    this.payment.lastName = this.order.lastName;
    this.payment.netReceivableAmount = this.order.netAmount;
    this.payment.transactionAmount = this.order.totalOrderAmount;
    this.payment.currency = this.token.getProperty().localCurrency;
    this.payment.propertyId = this.token.getProperty().id;
    this.payment.amount = this.order.totalOrderAmount;
    this.payment.businessEmail = this.token.getProperty().email;
    this.payment.taxAmount = this.order.taxAmount;
    this.payment.date = this.datePipe.transform(
      new Date().getTime(),
      "yyyy-MM-dd"
    );
    this.payment.callbackUrl =
   (`https://bookone-customer-app.web.app/#//order-success?orderId=${this.order.bookOneOrderId}`);
    this.savePaymentPayTm(this.payment);
  }
  savePaymentPayTm(payment: Payment) {
    this.loader = true;
    payment.date = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
    this.orderService.savePayment(payment).subscribe((response) => {
      if (response.status === 200) {
        this.payment = response.body;
        this.order.paymentId = this.payment.id;

        this.bookPayTm();
      } else {
        this.loader = false;
      }
    });
  }
  bookPayTm() {
    this.order.modeOfPayment = this.payment.paymentMode;
    this.order.deliveryMethod = "Pick From Store";
    this.order.currency = this.businessUser.localCurrency;
    this.order.shipToAddress = this.address;
    if (
      this.order.requiredDate == undefined ||
      this.order.requiredDate == null
    ) {
      this.order.requiredDate = new Date().getTime().toString();
    }
    if (
      this.order.requiredTime == undefined ||
      this.order.requiredTime == null
    ) {
      this.order.requiredTime = this.getOrderTimeformatAMPM(new Date());
    }
    this.orderService.order(this.order).subscribe(
      (data) => {
   
        this.order.modeOfPayment = this.payment.paymentMode;
        this.loader = false;
        this.order = data.body;
        this.payment.referenceNumber = data.body.bookOneOrderId;
        this.payment.externalReference = data.body.bookOneOrderId;
        this.payment.orderId = data.body.id;
        this.payment.name = data.body.firstName + " " + data.body.lastName;

        this.token.saveOrderData(data.body);
        // this.showToast("Order create successfully Order Id#" + data.body.id);
        if (this.businessUser.paymentGateway === 'paytm') {
          this.updatePaymentPayTm(this.payment);
        } else if  (this.businessUser.paymentGateway === 'phonepe') {
          this.processPaymentPhonepe(this.payment);
        }
        
      },
      () => {
        this.loader = false;
      }
    );
  }
  updatePaymentPayTm(payment) {
    // this.orderService.savePayment(payment).subscribe((res) => {
    // this.payment = res.body;

    this.orderService.paymentIntent(payment).subscribe((response) => {
      this.token.savePaymentData(response.body);
      this.router.navigate(["/listing/checkout-paytm"]);

    });
    // });
  }
    processPaymentPhonepe(payment: Payment) {
    this.paymentLoader = true;
    this.changeDetectorRefs.detectChanges();

    this.orderService.processPayment(payment).subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body.failureMessage !== null) {
            this.paymentLoader = false;
            this.isSuccess = false;
            this.headerTitle = "Error!";
            this.bodyMessage =
              "Unable to process payment" +
              " Code: " +
              response.body.failureMessage;
            this.showDanger(this.contentDialog);

            this.changeDetectorRefs.detectChanges();
          } else {
            this.paymentLoader = false;
            this.payment = response.body;

            //for post booking create

            this.paymentIntentPhonepe(this.payment);

            // for pre booking create

            // this.addServiceToBooking(this.booking);
          }
        } else {
          this.paymentLoader = false;
          this.isSuccess = false;
          this.headerTitle = "Error!";
          this.bodyMessage = "Payment Failed! Code: " + response.status;
          this.showDanger(this.contentDialog);
          this.changeDetectorRefs.detectChanges();
        }
      },
      (error) => {
        this.paymentLoader = false;
        this.isSuccess = false;
        this.headerTitle = "Error!";
        this.bodyMessage = "Payment Failed! Code: " + error.status;
        this.showDanger(this.contentDialog);
        this.changeDetectorRefs.detectChanges();
      }
    );
  }
  paymentIntentPhonepe(payment: Payment) {
    this.paymentLoader = true;

    this.orderService.paymentIntentPhonepe(payment).subscribe((response) => {
      this.paymentLoader = false;
      if (response.status === 200) {
        this.payment = response.body;

        this.token.savePaymentData(response.body);
        this.token.savePropertyData(this.businessUser);
        this.router.navigate(["/listing/checkout-phonepay"]);
      }
    });
  }

  OnChangeRoom(){
    this.homeDelivery = false;
    this.pickfromstore = false;
    this.roomOrder = true;
    this.getGuestsInHouseToday(this.order.orderedDate);
  }
  onBookingDetailsSelected(data) {
    this.bookingSearchResult = "";
    this.order.bookingId = undefined;
    this.order.customerId = undefined;
    this.order.firstName = undefined;
    this.order.lastName = undefined;
    this.order.email = undefined;
    this.order.mobile = undefined;
    this.order.planName = undefined;
    this.customer.id = undefined;
    this.customer.firstName = undefined;
    this.customer.lastName = undefined;
    this.customer.email = undefined;
    this.customer.mobile = undefined;
    this.payment.referenceNumber = undefined;
    // this.paymentReservation.referenceNumber = undefined;

    this.roomDetails = [];
    this.setBooking(data.bookingOb);
    this.setRoom(data.roomNumber);
    this.RoomNo = data.roomNumber;
    this.RoomNo = this.order.roomNo;
    this.isShowBookingList = false;
  }

  setBooking(book) {

    this.roomDetails = [];
    this.booking = book;

    if (this.booking?.groupBooking === false) {
      this.roomDetails = this.booking?.roomDetails;
    } else {
      for (let i = 0; i < this.booking?.roomDetails.length; i++) {
        if (
          this.booking?.roomDetails[i].guestName ===
          this.booking?.firstName + " " + this.booking?.lastName
        ) {
          this.roomDetails = [];
          this.roomDetails.push(this.booking?.roomDetails[i]);
        }
      }
    }

    if (
      this.booking?.groupBooking === true &&
      this.roomDetails != null &&
      this.roomDetails != undefined &&
      this.roomDetails.length > 0
    ) {
      this.order.bookingId = this.booking?.id;
      this.order.customerId = this.roomDetails[0].customerId;
      this.order.firstName = this.booking?.firstName;
      this.order.lastName = this.booking?.lastName;
      this.order.planName = this.booking?.roomRatePlanName;
      this.order.email = "";
      this.order.mobile = "";

      this.customer.id = this.roomDetails[0].customerId;
      this.customer.firstName = this.booking?.firstName;
      this.customer.lastName = this.booking?.lastName;
      this.customer.email = "";
      this.customer.mobile = "";
    } else {
      this.order.bookingId = this.booking?.id;
      this.order.customerId = this.booking?.customerId;
      this.order.firstName = this.booking?.firstName;
      this.order.lastName = this.booking?.lastName;
      this.order.email = this.booking?.email;
      this.order.mobile = this.booking?.mobile;
      this.order.planName = this.booking?.roomRatePlanName;

      this.customer.id = this.booking?.customerId;
      this.customer.firstName = this.booking?.firstName;
      this.customer.lastName = this.booking?.lastName;
      this.customer.email = this.booking?.email;
      this.customer.mobile = this.booking?.mobile;
    }

    this.isCustomerInfoReadOnly = true;

    this.payment.referenceNumber = book.propertyReservationNumber;
    // this.paymentReservation.referenceNumber = book.propertyReservationNumber;
  }
  setRoom(roomno) {
    this.order.roomNo = roomno;
    this.payment.roomNumber = roomno;
  }

  getGuestsInHouseToday(dateString: string) {
    this.bookings = [];
    this.bookingFilter = [];
    this.hotelBookingService
      .getGuestInHouseForRoomOrder(this.order.propertyId)
      .subscribe((data) => {
        this.bookings = data.body;

        this.bookingdata = [];
        for (let i = 0; i < this.bookings.length; i++) {
          if (
            this.bookings[i].roomDetails != null &&
            this.bookings[i].roomDetails != undefined &&
            this.bookings[i].roomDetails.length > 0
          ) {
            if (this.bookings[i].groupBooking === false) {
              for (let j = 0; j < this.bookings[i].roomDetails.length; j++) {
                const data: BookingData = {
                  id: this.bookings[i].id,
                  firstName: this.bookings[i].firstName,
                  lastName: this.bookings[i].lastName,
                  email: this.bookings[i].email,
                  mobile: this.bookings[i].mobile,
                  propertyReservationNumber:
                    this.bookings[i].propertyReservationNumber,
                  roomName: this.bookings[i].roomName,
                  isGroupBooking: false,
                  customerId: this.bookings[i].customerId,
                  roomNumber: this.bookings[i].roomDetails[j].roomNumber,
                  bookingOb: this.bookings[i],
                };
                this.bookingdata.push(data);
              }
            } else {
              for (let j = 0; j < this.bookings[i].roomDetails.length; j++) {
                if (
                  this.bookings[i].firstName +
                    " " +
                    this.bookings[i].lastName ===
                  this.bookings[i].roomDetails[j].guestName
                ) {
                  const data: BookingData = {
                    id: this.bookings[i].id,
                    firstName: this.bookings[i].firstName,
                    lastName: this.bookings[i].lastName,
                    email: this.bookings[i].email,
                    mobile: this.bookings[i].mobile,
                    propertyReservationNumber:
                      this.bookings[i].propertyReservationNumber,
                    roomName: this.bookings[i].roomName,
                    roomNumber: this.bookings[i].roomDetails[j].roomNumber,
                    isGroupBooking: true,
                    customerId: this.bookings[i].roomDetails[j].customerId,
                    bookingOb: this.bookings[i],
                  };
                  this.bookingdata.push(data);
                }
              }
            }
            this.bookingdata.sort((a, b) => b.id - a.id);
            this.bookingdata = this.bookingdata.filter(
              (test, index, array) =>
                index ===
                array.findIndex(
                  (findTest) => findTest.roomNumber === test.roomNumber
                )
            );
            this.bookingdata.sort((a, b) => a.roomNumber - b.roomNumber);
          }
        }

        this.bookingFilter = this.bookingdata;

        if (
          this.isNewOrderCreated === false ||
          this.isOrderIdAvailable === false
        ) {
          if (
            this.order.bookingId != null &&
            this.order.bookingId != undefined
          ) {
            this.booking = this.bookings.find(
              (data) => data.id === this.order.bookingId
            );

            this.roomDetails = this.booking?.roomDetails;
            this.RoomNo = this.order.roomNo;
          }
        }

        this.loader = false;
        // this.UIDetectChange();
      }),
      (error) => {
        this.loader = false;
      };
  }


  getOrderTimeformatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  // getOfferDetails() {
  //   this.orderService
  //     .getOfferDetailsBySeoFriendlyName(this.businessUser.seoFriendlyName)
  //     .subscribe((data) => {
  //       this.businessOfferDto = data.body;
  //       console.log("this.businessOfferDto: ", data.body);
  //     });
  // }
  getDeliveryDate(date: Date) {
    let currentDay: string;
    let currentMonth: string;

    if (date.getDate().toString().length == 1) {
      currentDay = "0" + date.getDate();
    } else {
      currentDay = "" + date.getDate();
    }

    if ((date.getMonth() + 1).toString().length == 1) {
      currentMonth = "0" + (date.getMonth() + 1);
    } else {
      currentMonth = "" + (date.getMonth() + 1);
    }

    this.delivertDateAndTime =
      currentDay + "-" + currentMonth + "-" + date.getFullYear();
    return date.getFullYear() + "-" + currentMonth + "-" + currentDay;
  }

  getDateDBFormat(date: Date) {
    let currentDay: string;
    let currentMonth: string;

    if (date.getDate().toString().length == 1) {
      currentDay = "0" + date.getDate();
    } else {
      currentDay = "" + date.getDate();
    }

    if ((date.getMonth() + 1).toString().length == 1) {
      currentMonth = "0" + (date.getMonth() + 1);
    } else {
      currentMonth = "" + (date.getMonth() + 1);
    }

    this.orderDateUI =
      currentDay + "-" + currentMonth + "-" + date.getFullYear();
    return date.getFullYear() + "-" + currentMonth + "-" + currentDay;
  }

  clickPhone() {
    this.order.email = "";
  }

  clickEmail() {
    this.order.mobile = "";
  }

  ngAfterViewInit() {
    const radios = document.querySelectorAll(".payment-tab-trigger > input");

    for (let i = 0; i < radios.length; i++) {
      radios[i].addEventListener("change", expandAccordion);
    }

    function expandAccordion(event) {
      const allTabs = document.querySelectorAll(".payment-tab");
      for (let i = 0; i < allTabs.length; i++) {
        allTabs[i].classList.remove("payment-tab-active");
      }
      event.target.parentNode.parentNode.classList.add("payment-tab-active");
    }
    this.calculatePrice();
  }
  telInputObject(obj) {
    //  this.order.mobile = obj;
    // obj.setCountry('nz');
    Logger.log(JSON.stringify(obj));
  }
  phoneHasError(obj) {
    Logger.log(JSON.stringify(obj));
    this.mobileHasError = obj;
  }
  getNumber(obj) {
    this.order.mobile = obj;
    Logger.log("get Number: ", JSON.stringify(this.order.mobile));
  }
  onVerified() {
    // this.isVerified = true;
    this.verified = true;
  }
  mobileTextChange() {
    this.mobileHasError = true;
    this.isVerified = false;
  }
  // customerLookup() {
  //   if (this.verifyOption == "email") {
  //     this.orderService.getCustomerDetailsByEmail(this.order.email).subscribe(
  //       (data) => {
  //         this.customer = data.body;
  //         // this.order.customerId = data.body.id;
  //         // Logger.log('Get customer ' + JSON.stringify(data.body));
  //         this.order.firstName = data.body.firstName;
  //         this.order.lastName = data.body.lastName;
  //         this.order.mobile = data.body.mobile;
  //         // this.order.customerId = data.body.id;
  //         this.lookup = true;
  //         this.customerExist = true;
  //         this.verified = true;
  //       },
  //       (_error) => {
  //         this.loader = false;
  //         this.lookup = true;
  //         this.customerExist = false;
  //       }
  //     );
  //   } else if (this.verifyOption == "sms") {
  //     this.orderService.getCustomerDetailsByMobile(this.order.mobile).subscribe(
  //       (data) => {
  //         this.customer = data.body;
  //         // this.order.customerId = data.body.id;
  //         //  Logger.log('Get customer ' + JSON.stringify(data.body));
  //         this.order.firstName = data.body.firstName;
  //         this.order.lastName = data.body.lastName;
  //         // this.order.mobile = data.body.mobile;
  //         this.order.email = data.body.email;
  //         // this.order.customerId = data.body.id;
  //         this.lookup = true;
  //         this.customerExist = true;
  //         this.verified = true;
  //       },
  //       (_error) => {
  //         this.loader = false;
  //         this.lookup = true;
  //         this.customerExist = false;
  //       }
  //     );
  //   }
  // }
  onReload() {
    this.order.email = '';
    this.order.mobile = '';
  }
  backClicked() {
    window.location.reload();
  }

  onHomeDelivery() {
    this.order.deliveryMethod = "Home Delivery";
    this.tableNumber = '';
    this.homeDelivery = true;
    this.tableNumber = '';
    this.order.deliveryChargeAmount = this.deliveryOptionObject.charges;
    this.totalAmount =
      this.subTotalAmount +
      this.order.deliveryChargeAmount +
      this.order.taxAmount -
      this.order.discountAmount;
    this.order.totalOrderAmount = this.totalAmount;
     

    if (
      this.customer != null &&
      this.customer != undefined &&
      this.customer.address != null &&
      this.customer.address != undefined
    ) {
      this.address = this.customer.address;
      this.order.shipToAddress = this.address;
    }
    this.claculateDeliveryTime(this.businessServiceDto);
    this.DeliveryOptionTimeSetup();
  }
  dineIn(){
    this.order.deliveryMethod = "Dine In";
    this.homeDelivery = false;
    this.pickfromstore = false;
    this.order.resourceName = this.tableNumber;
    this.order.deliveryChargeAmount = 0;
    if(this.order.taxAmount == null || this.order.taxAmount == undefined) {
      this.order.taxAmount = 0;
    };
    if(this.order.discountAmount == null || this.order.discountAmount == undefined) {
      this.order.discountAmount = 0;
    }
    this.totalAmount =
      this.subTotalAmount + this.order.taxAmount - this.order.discountAmount;
      
      
    this.order.totalOrderAmount = Math.round(this.totalAmount);

    this.claculateDeliveryTime(this.businessServiceDto);
    this.DeliveryOptionTimeSetup();
 
  }
  pickFromStore() {
    this.order.deliveryMethod = "Pick From Store";
    this.tableNumber = '';
    this.homeDelivery = false;
    this.pickfromstore = true;
    this.tableNumber = '';
    this.order.deliveryChargeAmount = 0;
    this.order.resourceName = this.tableNumber;
    this.totalAmount =
      this.subTotalAmount + this.order.taxAmount - this.order.discountAmount;
      this.order.totalOrderAmount = Math.round(this.totalAmount);
      
    this.claculateDeliveryTime(this.businessServiceDto);
    this.DeliveryOptionTimeSetup();
  }
  cashOnDelivery() {
    this.cashPayment = true;
  }
  cardPayment() {
    this.cashPayment = false;

    if (this.businessUser.paymentGateway == "eway") {
      this.initEWay();
      this.cardPaymentAvailable = true;
    } else if (this.businessUser.paymentGateway == "stripe") {
      this.loadStripe();
      if (this.homeDelivery == true) {
        this.order.shipToAddress = this.address;
      } else {
        this.order.shipToAddress = this.businessUser.address;
        this.order.deliveryChargeAmount = 0;
      }

      this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
      this.order.orderStatus = "Submitted";
      this.payment.paymentMode = "Card";
      this.payment.status = "Paid";
      this.payment.email = this.order.email;
      this.payment.firstName = this.order.firstName;
      this.payment.lastName = this.order.lastName;
      this.payment.netReceivableAmount = this.order.netAmount;
      this.payment.transactionAmount = this.order.totalOrderAmount;
      this.payment.businessEmail = this.businessUser.email;
      this.payment.transactionChargeAmount = this.order.totalOrderAmount;
      this.payment.amount = this.order.totalOrderAmount;
      this.payment.propertyId = this.order.propertyId;
      this.payment.taxAmount = this.order.taxAmount;
      this.payment.currency = this.businessUser.localCurrency;
      this.payment.deliveryChargeAmount = this.order.deliveryChargeAmount;

      this.paymentIntent(this.payment);
      this.cardPaymentAvailable = true;
    } else {
      this.cardPaymentAvailable = false;
    }
  }
  bankPayment() {
    this.cashPayment = false;
  }
  
  calculateTax() {
    if (
      this.businessUser.gstNumber !== undefined &&
      this.businessUser.gstNumber !== null &&
      this.businessUser.gstNumber !== ""
    ) {
   
      for (const businessService of this.businessUser.businessServiceDtoList) {
        if (businessService.id == this.businessServiceId) {
          if (businessService.taxDetails?.length) {
            this.taxDetails = [];
            this.taxDetails = businessService.taxDetails;
            
            this.taxPercentage = this.taxDetails[0].percentage;

            if (this.taxDetails[0].taxSlabsList?.length) {
              this.taxDetails[0].taxSlabsList.forEach((element) => {
                if (
                  element.maxAmount >= this.subTotalAmount &&
                  element.minAmount <= this.subTotalAmount
                ) {
                  this.taxPercentage = element.percentage;
                } else if (element.maxAmount <= this.subTotalAmount) {
                  this.taxPercentage = element.percentage;
                }
              });
            }
          } else {
            if (this.businessUser.taxDetails.length > 0) {
              this.taxPercentage = this.businessUser.taxDetails[0].percentage;
            }
            if (this.businessUser.taxDetails[0].taxSlabsList.length > 0) {
              this.businessUser.taxDetails[0].taxSlabsList.forEach(
                (element) => {
                  if (
                    element.maxAmount >= this.subTotalAmount &&
                    element.minAmount <= this.subTotalAmount
                  ) {
                    this.taxPercentage = element.percentage;
                  } else if (element.maxAmount <= this.subTotalAmount) {
                    this.taxPercentage = element.percentage;
                  }
                }
              );
            }
          }
       
          
        }
      }

      // if(this.businessUser.taxDetails != null && this.businessUser.taxDetails != undefined && this.businessUser.taxDetails.length >0)
      // {
      //   this.taxPercentage = this.businessUser.taxDetails[0].percentage;
      // }
      // else
      // {
      //   this.taxPercentage = 0;
      // }
  
  this.taxPercentageDetails.push(this.taxPercentage);
  this.taxPercentage = this.taxPercentageDetails.reduce((accumulator, currentValue) => accumulator + currentValue, 0);


    return this.taxPercentage;

  } else {
    this.taxPercentage = 0;
  }
  }
 

  getAllDeliveryOptionByServiceId(businessServiceId: number) {
    this.loader = true;
    this.orderService.getDeliveryOption(businessServiceId).subscribe(
      (data) => {
        if (data !== null) {
          this.deliveryOption = data.body;
          if (
            this.deliveryOption != undefined &&
            this.deliveryOption.length > 0
          ) {
            this.deliveryOptionObject = this.deliveryOption[0];
            this.deliveryChargeOption = this.deliveryOption[0].code;
            this.deliveryCharge = this.deliveryOption[0].charges;
            this.order.deliveryChargeAmount = this.deliveryCharge;

            if (this.homeDelivery === true) {
              this.order.totalOrderAmount =
                this.order.netAmount +
                this.order.taxAmount +
                this.order.deliveryChargeAmount -
                this.order.discountAmount;
            } else {
              this.order.totalOrderAmount =
                this.order.netAmount +
                this.order.taxAmount -
                this.order.discountAmount;
            }

            this.totalAmount = this.order.totalOrderAmount;
            
            
            this.order.deliveryCode = this.deliveryOption[0].code;
            this.payment.deliveryChargeAmount = this.deliveryOption[0].charges;
          }

          this.loader = false;
          this.changeDetectorRefs.detectChanges();
        } else {
          this.deliveryChargeOption = "";
          this.deliveryCharge = 0;
          this.order.deliveryChargeAmount = this.deliveryCharge;

          if (this.homeDelivery === true) {
            this.order.totalOrderAmount =
              this.order.netAmount +
              this.order.taxAmount +
              this.order.deliveryChargeAmount -
              this.order.discountAmount;
              
          } else {
            this.order.totalOrderAmount =
              this.order.netAmount +
              this.order.taxAmount -
              this.order.discountAmount;
              
          }

          this.totalAmount = this.order.totalOrderAmount;
          
          this.order.deliveryCode = "";
          this.payment.deliveryChargeAmount = 0;
          this.loader = false;
          this.changeDetectorRefs.detectChanges();
        }
      },
      (error) => {
        this.loader = false;
        // Logger.log(JSON.stringify(error));
      }
    );
  }
  setDeliveryCharge(charge) {

    this.deliveryOptionObject = this.deliveryOption.find(
      (data) => data.code === charge
    );
    this.deliveryCharge = this.deliveryOptionObject.charges;
    this.order.deliveryChargeAmount = this.deliveryCharge;

    if (this.homeDelivery === true) {
      this.order.totalOrderAmount =
        this.order.netAmount +
        this.order.taxAmount +
        this.order.deliveryChargeAmount -
        this.order.discountAmount;
    } else {
      this.order.totalOrderAmount =
        this.order.netAmount + this.order.taxAmount - this.order.discountAmount;
        
    }

    this.order.deliveryCode = this.deliveryOptionObject.code;
    this.payment.deliveryChargeAmount = this.deliveryCharge;
    this.totalAmount = this.order.totalOrderAmount;
    
    this.claculateDeliveryTime(this.businessServiceDto);
    this.DeliveryOptionTimeSetup();
  }
  applyPromoCode(offer) {
    if (offer !== "") {
      let currentDate = new Date().getTime();
      if (offer.startDate <= currentDate && currentDate <= offer.endDate) {
        if (offer.minimumOrderAmount < this.order.netAmount) {
          if (this.homeDelivery == true) {
            this.deliveryChargeAmount = this.order.deliveryChargeAmount;
          } else {
            this.deliveryChargeAmount = 0;
          }
          this.discountPercentage = offer.discountPercentage;
          this.order.discountPercentage = offer.discountPercentage;
          this.order.discountAmount =
            this.order.netAmount * (this.order.discountPercentage / 100);
          this.order.taxAmount =
            (this.order.netAmount * this.taxPercentage) / 100;
            
          this.order.totalOrderAmount =
            this.order.netAmount +
            this.order.taxAmount +
            this.deliveryChargeAmount -
            this.order.discountAmount;
          this.totalAmount = this.order.totalOrderAmount;
          
          this.order.couponCode = offer.couponCode;
          this.promoMessage = offer.couponCode + " applied!";
        } else {
          this.promoMessage =
            "Minimum order amount is " + offer.minimumOrderAmount;
        }
      } else {
        this.discountPercentage = 0;
        this.order.discountPercentage = 0;
        this.promoMessage = offer.couponCode + " has expired!";
      }
    } else {
      if (this.homeDelivery == true) {
        this.deliveryChargeAmount = this.order.deliveryChargeAmount;
      } else {
        this.deliveryChargeAmount = 0;
      }
      this.discountPercentage = 0;
      this.order.discountPercentage = 0;
      this.order.discountAmount =
        this.order.netAmount * (this.order.discountPercentage / 100);
      this.order.taxAmount = (this.order.netAmount * this.taxPercentage) / 100;
      
      this.order.totalOrderAmount =
        this.order.netAmount +
        this.order.taxAmount +
        this.deliveryChargeAmount -
        this.order.discountAmount;
      this.totalAmount = this.order.totalOrderAmount;
      

      this.promoMessage = "Not available!";
    }
  }
  claculateDeliveryTime(businessServiceDto: BusinessServiceDtoList) {
    this.serviceOpenDto = new ServiceOpenDto();
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let totalHour = 0;
    let totalDay = 0;
    let totalMin = 0;
    const workingHour = 0;

    if (this.homeDelivery === true) {
      totalHour = this.prepareHour;
      totalDay = this.prepareDay;
      totalMin = this.prepareMinute;
    } else {
      totalHour = this.prepareHour + this.leadMaxHour;
      totalDay = this.leadMaxDay + this.prepareDay;
      totalMin = this.leadMaxMin + this.prepareMinute;
    }

    const afterDate: Date = new Date();
    const currentDate: Date = new Date();

    afterDate.setDate(afterDate.getDate() + totalDay);
    afterDate.setMinutes(totalMin + currentDate.getMinutes());
    afterDate.setHours(currentDate.getHours() + totalHour);

    if (this.homeDelivery === true) {
      if (
        this.deliveryOption != undefined &&
        this.deliveryOptionObject.maxTime != undefined &&
        this.deliveryOptionObject.maxTime != null
      ) {
        afterDate.setMinutes(
          Number(this.deliveryOptionObject.maxTime) +
            totalMin +
            currentDate.getMinutes()
        );
      }
    }

    if (
      this.businessServiceDto != undefined &&
      this.businessServiceDto.serviceOpenList != undefined &&
      this.businessServiceDto.serviceOpenList.length > 0
    ) {
      this.serviceOpenDto = businessServiceDto.serviceOpenList.find(
        (data) =>
          data.day.toLocaleUpperCase() ===
          days[afterDate.getDay()].toLocaleUpperCase()
      );
    }

    if (this.serviceOpenDto === undefined) {
      Logger.log("off day");
      this.isWorkingTime = false;
    } else {
      const startTime: Date = new Date();
      startTime.setDate(startTime.getDate());
      startTime.setHours(Number(this.serviceOpenDto.openingTime));

      const endTime: Date = new Date();
      endTime.setDate(endTime.getDate());
      endTime.setHours(Number(this.serviceOpenDto.closingTime));

      if (
        startTime.getTime() <= afterDate.getTime() &&
        afterDate.getTime() <= endTime.getTime()
      ) {
        Logger.log("on time");
        this.isWorkingTime = true;
      } else {
        Logger.log("off time");
        this.isWorkingTime = false;
      }
    }

    this.delivertDateAndTime =
      this.getDateDBFormat(afterDate) +
      " " +
      this.getOrderTimeformatAMPM(afterDate);
    this.order.orderedDate = this.getDateDBFormat(currentDate);
    this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
    this.order.requiredDate = this.getDateDBFormat(afterDate);
    this.order.requiredTime = this.getOrderTimeformatAMPM(afterDate);
    // Logger.log(' this.delivertDateAndTime' + this.delivertDateAndTime);
  }

  DeliveryOptionTimeSetup() {
    this.leadMaxMinTotal = undefined;
    this.leadMinTotal = undefined;
    this.leadMaxDayTotal = undefined;

    this.leadMinTotal = undefined;
    this.leadHourTotal = undefined;
    this.leadDayTotal = undefined;

    if (this.homeDelivery === true) {
      if (
        this.deliveryOptionObject != undefined &&
        this.deliveryOptionObject != null
      ) {
        //let finalMin = this.prepareMinute + this.deliveryOptionObject.maxTime;
        // if(Math.trunc(finalMin/60) === 0)
        // {
        //   this.leadMaxMinTotal =  finalMin%60;
        //   this.leadMaxHourTotal = this.prepareHour;
        // }
        // else
        // {
        //   this.leadMaxMinTotal = finalMin%60;
        //   this.leadMaxHourTotal = this.prepareHour + Math.trunc(finalMin/60);
        // }

        this.leadMaxMinTotal =
          this.prepareMinute + this.deliveryOptionObject.maxTime;
        this.leadMaxHourTotal = this.prepareHour;
        this.leadMaxDayTotal = this.prepareDay;

        this.leadMinTotal =
          this.prepareMinute + this.deliveryOptionObject.minTime;
        this.leadHourTotal = this.prepareHour;
        this.leadDayTotal = this.prepareDay;
      } else {
        this.leadMaxMinTotal = this.prepareMinute;
        this.leadMaxHourTotal = this.prepareHour;
        this.leadMaxDayTotal = this.prepareDay;

        this.leadMinTotal = this.prepareMinute;
        this.leadHourTotal = this.prepareHour;
        this.leadDayTotal = this.prepareDay;
      }
    } else {
      this.leadMaxMinTotal = this.leadMaxMin;
      this.leadMaxHourTotal = this.leadMaxHour;
      this.leadMaxDayTotal = this.leadMaxDay;

      this.leadMinTotal = this.leadMin;
      this.leadHourTotal = this.leadHour;
      this.leadDayTotal = this.leadDay;
    }
  }

  createAuditReport(prevOrder : Order , currentOrder : Order, operationType : string)
  {
    // this.role = [];
    // JSON.parse(this.token.getRole()).forEach((item) => {
    //   this.role.push(item);
    // });

    let audit = new Audit();

    audit.auditType = operationType;
    audit.orderId = this.order.id;
    audit.propertyId = this.order.propertyId;
    audit.role = 'website';
    audit.updatedAt = new Date().getTime().toString();
    audit.updatedBy = this.businessUser.managerFirstName + '' + this.businessUser.managerLastName;
    audit.reservationId = this.order.bookOneOrderId;

    if (AUDIT_ORDER_CREATE === operationType)
    {
      if (this.pickfromstore === false) {

        let newItems = this.addToCartList.map(product => product.name).join(",");

        audit.previousValue = "";
        audit.newValue = `Order date:${this.dateService.convertMillisecondsToDateFormat(currentOrder.orderedDate)},Name: ${currentOrder.firstName},Email:${currentOrder.email},Mobile:${currentOrder.mobile},deliveryMethod:${currentOrder.deliveryMethod},Resource:${currentOrder.resourceName},Location:${currentOrder.locationName},Discount:${currentOrder.discountAmount}, Total Items:${currentOrder.totalOrderAmount} , Total Items:(${this.calculateQuantity()}).`;

      } else if (this.pickfromstore === true) {
        let newItems = this.addToCartList.map(product => product.name).join(",");
        audit.bookingId = currentOrder.bookingId;
        audit.previousValue = "";
        audit.newValue = `Order date:${this.dateService.convertMillisecondsToDateFormat(currentOrder.orderedDate)}, Name: ${currentOrder.firstName},Email:${currentOrder.email},Mobile:${currentOrder.mobile},deliveryMethod:${currentOrder.deliveryMethod},Room No:${currentOrder.roomNo},Discount:${currentOrder.discountAmount}, Total:${currentOrder.totalOrderAmount} , Total Items:(${this.calculateQuantity()}).`;

      }
   

      audit.operatorNotes = "";
      audit.updateType = "New Order";

    } 

    this.loader = true;
    this.orderService.createAuditReport(audit).subscribe(
      (data) => {
        this.loader = false;

        this.changeDetectorRefs.detectChanges();
      },
      (error) => {
        this.loader = false;
      }
    );
  }
  // checkCustomer() {
  //   this.loader = true;

  //   if (this.verifyOption == "email") {
  //     this.message.email = this.order.email;
  //     this.message.toNumber = null;
  //   } else if (this.verifyOption == "sms") {
  //     this.message.toNumber = this.order.mobile;
  //     this.message.email = null;
  //   }

  //   this.sendBtn = "Resend";
  //   this.orderService.authorisationToken(this.message).subscribe((response) => {
  //     this.loader = false;
  //     // Logger.log('authorisationToken data', JSON.stringify(response));
  //     const data: any = response;
  //     this.message.verificationStatus = data.verificationStatus;
  //     this.message.sid = data.sid;
  //     this.message.notificationStatus = data.notificationStatus;
  //   }),
  //     (error) => {
  //       this.loader = false;
  //     };
  //   // Logger.log('authorisationToken data', JSON.stringify(this.message));
  //   this.lookup = true;
  //   this.loader = false;
  //   this.verificationSend = true;
  // }
  // varificationSend() {
  //   this.loader = true;

  //   if (this.verifyOption == "email") {
  //     this.message.email = this.order.email;
  //     this.message.toNumber = null;
  //   } else if (this.verifyOption == "sms") {
  //     this.message.toNumber = this.order.mobile;
  //     this.message.email = null;
  //   }
  //   this.message.verificationCode = this.verificationCode;
  //   this.orderService.verifyAuthorisationToken(this.message).subscribe(
  //     (response) => {
  //       this.loader = false;

  //       // Logger.log('verification data', JSON.stringify(response));
  //       const data: any = response;
  //       this.message.verificationStatus = data.verificationStatus;
  //       this.message.notificationStatus = data.notificationStatus;
  //       if (data.verificationStatus === "approved") {
  //         this.verifiedPending = false;
  //         this.verified = true;
  //         this.verifySuccessMessage = true;
  //         setTimeout(function () {
  //           this.verifySuccessMessage = false;
  //         }, 5000);
  //       } else if (data.verificationStatus === "pending") {
  //         this.isSuccess = false;
  //         this.verifiedPending = true;
  //         this.verified = false;
  //         this.verifySuccessMessage = true;
  //         setTimeout(function () {
  //           this.verifySuccessMessage = false;
  //         }, 5000);
  //       } else {
  //         this.verified = false;
  //       }
  //     },
  //     (_error) => {
  //       this.loader = false;
  //     }
  //   );
  // }

  onLocalitySelect(selected: any) {
    if (selected !== "") {
      this.suburbsLists = this.localityList.find(
        (service) => service.city === selected
      );
    }
    // Logger.log('localityList selected list: ' + JSON.stringify(this.suburbsLists));
  }
  onSubmit(_form) {}
  calculateQuantity() {
    this.totalQuantity = 0;

    for (let i = 0; i < this.addToCartList.length; i++) {
      if (this.addToCartList[i].variationDtoList != undefined) {
        for (
          let j = 0;
          j < this.addToCartList[i].variationDtoList.length;
          j++
        ) {
          this.totalQuantity = this.totalQuantity + 1;
        }
      } else {
        this.totalQuantity = this.totalQuantity + 1;
      }
    }

    return this.totalQuantity;
  }

  calculatePrice() {
    this.totalPrice = 0;
    for (let i = 0; i < this.addToCartList.length; i++) {
      if (
        this.addToCartList[i].totalPrice != undefined ||
        this.addToCartList[i].totalPrice != null
      ) {
        this.totalPrice = this.totalPrice + this.addToCartList[i].totalPrice;
      }
      if(this.addToCartList[i].productDtoList != undefined){
        for (let k = 0; k < this.addToCartList[i].productDtoList.length; k++) {
          if (this.addToCartList[i].productDtoList[k].unitsInOrder == 1) {
            this.totalPrice =
              this.totalPrice +
              this.addToCartList[i].productDtoList[k].sellUnitPrice;
          }
        }
      }
      if(this.addToCartList[i].toppingproductDtoList != undefined){
        for (let m = 0; m < this.addToCartList[i].toppingproductDtoList.length; m++) {
          if (this.addToCartList[i].toppingproductDtoList[m].unitsInOrder == 1) {
            this.totalPrice =
              this.totalPrice +
              this.addToCartList[i].toppingproductDtoList[m].sellUnitPrice;
          }
        }
      }
      

      if (this.addToCartList[i].variationDtoList != undefined) {
        for (
          let j = 0;
          j < this.addToCartList[i].variationDtoList.length;
          j++
        ) {
          this.totalPrice =
            this.totalPrice +
            this.addToCartList[i].variationDtoList[j].totalPrice;
        }
      }
    }

    this.token.saveProductCart(this.addToCartList);
    this.addPropertyDataFromAddtoCart();
    // if (this.businessUser.taxDetails != undefined) {
    //   this.taxPercentage = this.businessUser.taxDetails[0].percentage;
    // }
    this.taxPercentage = this.calculateTax();
    if (this.deliveryOption != null && this.deliveryOption != undefined) {
      this.deliveryOption.forEach((element) => {
        if (this.subTotalAmount >= element.minOrderAmount) {
          this.deliveryCharge = element.charges;
        }
      });

      Logger.log(" this.deliveryCharge " + this.deliveryCharge);
    }

    this.subTotalAmount = this.totalPrice;
    this.order.netAmount = this.subTotalAmount;
    if(this.taxPercentage != null || this.taxPercentage != undefined) {
      this.order.taxAmount = this.subTotalAmount * (this.taxPercentage / 100);
    } else {
      this.order.taxAmount = 0;
    }
    
    
    // this.order.totalOrderAmount = this.subTotalAmount + this.order.taxAmount;

    if (this.homeDelivery == true) {
      this.order.totalOrderAmount =
        this.order.netAmount +
        this.order.taxAmount +
        this.order.deliveryChargeAmount -
        this.order.discountAmount;
    } else {
      this.order.totalOrderAmount =
        this.order.netAmount + this.order.taxAmount - this.order.discountAmount;
    }

    
    this.totalAmount = this.order.totalOrderAmount;
    this.order.totalOrderAmount = Math.round(this.totalAmount);
    
 
    this.order.discountAmount = 0;
    this.changeDetectorRefs.detectChanges();
    return this.totalPrice;
  }
  calculateProductDistount() {
    this.totalProductDiscount = 0;
    for (let i = 0; i < this.addToCartList.length; i++) {
      if (
        this.addToCartList[i].discountedPrice != 0 &&
        this.addToCartList[i].discountedPrice != null
      ) {
        this.totalProductDiscount =
          this.totalProductDiscount +
          (this.addToCartList[i].sellUnitPrice -
            this.addToCartList[i].discountedPrice) *
            this.addToCartList[i].quantityProduct;
      }

      if (this.addToCartList[i].variationDtoList != undefined) {
        for (
          let j = 0;
          j < this.addToCartList[i].variationDtoList.length;
          j++
        ) {
          if (
            this.addToCartList[i].variationDtoList[j].discountedPrice != 0 &&
            this.addToCartList[i].variationDtoList[j].discountedPrice != null
          ) {
            this.totalProductDiscount =
              this.totalProductDiscount +
              (this.addToCartList[i].variationDtoList[j].sellUnitPrice -
                this.addToCartList[i].variationDtoList[j].discountedPrice) *
                this.addToCartList[i].variationDtoList[j].quantityVariation;
          }
        }
      }
    }
  }
  removeProduct(product) {
    this.addToCartList = this.addToCartList.filter(
      ({ name }) => name !== product.name
    );
    this.token.saveProductCart(this.addToCartList);
    this.changeDetectorRefs.detectChanges();
  }

  removeProductVariatiion(product) {
    this.proVariationDtoLists = [];
    this.proVariationDtoList = new productVariationDtoList();

    this.proVariationDtoLists = product.variationDtoList;

    for (let i = 0; i < this.proVariationDtoLists.length; i++) {
      if (this.proVariationDtoLists[i].quantityVariation === 0) {
        const index: number = this.proVariationDtoLists.indexOf(
          this.proVariationDtoLists[i]
        );
        if (index !== -1) {
          this.proVariationDtoLists.splice(index, 1);

          // Logger.log(JSON.stringify(this.proVariationDtoLists));

          if (this.proVariationDtoLists.length === 0) {
            this.addToCartList = this.addToCartList.filter(
              ({ name }) => name !== product.name
            );
          }
        }
      }
      this.changeDetectorRefs.detectChanges();
    }

    this.token.saveProductCart(this.addToCartList);
  }

  onCardPaymentSubmit(content) {
    this.contentDialog = content;

    if (this.homeDelivery == true) {
      this.order.shipToAddress = this.address;
    } else {
      this.order.shipToAddress = this.businessUser.address;
      this.order.deliveryChargeAmount = 0;
    }

    this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
    this.order.orderStatus = "Submitted";
    this.payment.paymentMode = "Card";
    this.payment.status = "Paid";
    this.payment.email = this.order.email;
    this.payment.firstName = this.order.firstName;
    this.payment.lastName = this.order.lastName;
    this.payment.netReceivableAmount = this.order.netAmount;
    this.payment.transactionAmount = this.order.totalOrderAmount;
    this.payment.businessEmail = this.businessUser.email;
    this.payment.transactionChargeAmount = this.order.totalOrderAmount;
    this.payment.amount = this.order.totalOrderAmount;
    this.payment.propertyId = this.order.propertyId;
    this.payment.taxAmount = this.order.taxAmount;
    this.payment.currency = this.businessUser.localCurrency;
    this.payment.deliveryChargeAmount = this.order.deliveryChargeAmount;

    this.chargeCreditCard(this.payment);
  }

  onWalletPaymentSubmit(content) {
    if (this.homeDelivery == true) {
      this.order.shipToAddress = this.address;
    } else {
      this.order.shipToAddress = this.businessUser.address;
      this.order.deliveryChargeAmount = 0;
    }

    this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
    this.contentDialog = content;
    this.order.orderStatus = "Submitted";
    this.payment.paymentMode = "Wallet";
    this.payment.status = "Paid";
    this.payment.firstName = this.order.firstName;
    this.payment.lastName = this.order.lastName;
    this.payment.email = this.order.email;
    this.payment.netReceivableAmount = this.order.netAmount;
    this.payment.transactionAmount = this.order.totalOrderAmount;
    this.payment.currency = this.businessUser.localCurrency;
    this.payment.amount = this.order.totalOrderAmount;
    this.payment.transactionChargeAmount = this.order.totalOrderAmount;
    this.payment.businessEmail = this.businessUser.email;
    this.payment.propertyId = this.order.propertyId;
    this.payment.taxAmount = this.order.taxAmount;
    this.payment.deliveryChargeAmount = this.order.deliveryChargeAmount;

    this.loader = true;
    this.processPayment(this.payment);
    this.loader = false;
  }

  onBankPaymentSubmit(content) {
    if (this.homeDelivery == true) {
      this.order.shipToAddress = this.address;
    } else {
      this.order.shipToAddress = this.businessUser.address;
      this.order.deliveryChargeAmount = 0;
    }

    this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
    this.contentDialog = content;
    this.order.orderStatus = "Submitted";
    this.payment.paymentMode = "BankTransfer";
    this.payment.status = "Paid";
    this.payment.firstName = this.order.firstName;
    this.payment.lastName = this.order.lastName;
    this.payment.email = this.order.email;
    this.payment.netReceivableAmount = this.order.netAmount;
    this.payment.transactionAmount = this.order.totalOrderAmount;
    this.payment.currency = this.businessUser.localCurrency;
    this.payment.amount = this.order.totalOrderAmount;
    this.payment.transactionChargeAmount = this.order.totalOrderAmount;
    this.payment.businessEmail = this.businessUser.email;
    this.payment.propertyId = this.order.propertyId;
    this.payment.taxAmount = this.order.taxAmount;
    this.payment.deliveryChargeAmount = this.order.deliveryChargeAmount;

    this.loader = true;
    this.processPayment(this.payment);
    this.loader = false;
  }

  onCashPaymentSubmit(content) {
    if (this.homeDelivery == true) {
      this.order.shipToAddress = this.address;
    } else {
      this.order.shipToAddress = this.businessUser.address;
      this.order.deliveryChargeAmount = 0;
    }
    this.contentDialog = content;

    this.order.orderedTime = this.getOrderTimeformatAMPM(new Date());
    this.order.orderStatus = "Submitted";
    this.payment.paymentMode = "Cash";
    this.payment.status = "NotPaid";
    this.payment.email = this.order.email;
    this.payment.firstName = this.order.firstName;
    this.payment.lastName = this.order.lastName;
    this.payment.netReceivableAmount = this.order.netAmount;
    this.payment.transactionAmount = this.order.totalOrderAmount;
    this.payment.currency = this.businessUser.localCurrency;
    this.payment.businessEmail = this.businessUser.email;
    this.payment.propertyId = this.order.propertyId;
    this.payment.transactionChargeAmount = this.order.totalOrderAmount;
    this.payment.amount = this.order.totalOrderAmount;
    this.payment.taxAmount = this.order.taxAmount;
    this.payment.deliveryChargeAmount = this.order.deliveryChargeAmount;

    this.processPayment(this.payment);
  }
  processPayment(payment: Payment) {
    this.paymentLoader = true;
    payment.date = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
    this.orderService.processPayment(payment).subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body.failureMessage != null) {
            this.paymentLoader = false;
            this.isSuccess = false;
            this.headerTitle = "Error!";
            this.bodyMessage =
              "Unable to process payment" +
              " Code: " +
              response.body.failureMessage;
            this.showDanger(this.contentDialog);

            this.changeDetectorRefs.detectChanges();
          } else {
            this.payment = response.body;
            this.order.modeOfPayment = this.payment.paymentMode;
            this.order.paymentId = this.payment.id;
            this.payment.date = this.datePipe.transform(
              new Date().getTime(),
              "yyyy-MM-dd"
            );
            this.payment.taxAmount = this.order.taxAmount;
            this.orderService.savePayment(this.payment).subscribe(
              (res1) => {
                if (res1.status === 200) {
                  this.paymentLoader = false;
                  this.order.paymentId = res1.body.id;
                  this.order.modeOfPayment = this.payment.paymentMode;

                  this.changeDetectorRefs.detectChanges();

                  this.placeOrder();
                  
                } else {
                  this.paymentLoader = false;
                  this.isSuccess = false;
                  this.headerTitle = "Error!";
                  this.bodyMessage =
                    "Unable to save payment" + " Code: " + status;
                  this.showDanger(this.contentDialog);

                  this.paymentLoader = false;
                  this.changeDetectorRefs.detectChanges();
                }
              },
              (error) => {
                this.paymentLoader = false;
                this.isSuccess = false;
                this.headerTitle = "Error!";
                this.bodyMessage =
                  "Saving Payment Failed! Code: " + error.status;
                this.showDanger(this.contentDialog);
                this.changeDetectorRefs.detectChanges();
              }
            );
          }
        } else {
          this.paymentLoader = false;
          this.isSuccess = false;
          this.headerTitle = "Error!";
          this.bodyMessage = "Unable to process payment" + " Code: " + status;
          this.showDanger(this.contentDialog);

          this.changeDetectorRefs.detectChanges();
        }
      },
      (error) => {
        this.paymentLoader = false;
        this.isSuccess = false;
        this.headerTitle = "Error!";
        this.bodyMessage = "Payment Failed! Code: " + error.status;
        this.showDanger(this.contentDialog);
        this.changeDetectorRefs.detectChanges();
      }
    );
    if (this.payment.paymentMode === "Card" && this.payment.status === "Paid") {
      this.order.modeOfPayment = this.payment.paymentMode;
    } else if (
      this.payment.paymentMode === "Cash" &&
      this.payment.status === "NotPaid"
    ) {
      // this.orderService.savePayment(this.payment).subscribe((res1) => {
      //   if (res1.status === 200) {
      //     this.paymentLoader = false;
      //     this.order.paymentId = res1.body.id;
      //     // Logger.log('cash payment response ' + JSON.stringify(this.payment));
      //     this.order.modeOfPayment = this.payment.paymentMode;
      //     this.placeOrder();
      //   } else {
      //   }
      // });
    } else if (
      this.payment.paymentMode === "BankTransfer" &&
      this.payment.status === "NotPaid"
    ) {
      this.orderService.savePayment(this.payment).subscribe((res1) => {
        if (res1.status === 200) {
          this.paymentLoader = false;
          this.order.paymentId = res1.body.id;
          // Logger.log(
          //   'Bank Transfer payment response ' + JSON.stringify(this.payment)
          // );
          this.order.modeOfPayment = this.payment.paymentMode;
          this.placeOrder();
        } else {
        }
      });
    }
  }

  chargeCreditCard(payment: Payment) {
    this.paymentLoader = true;
    Logger.info("chargeCreditCard", payment);

    if (this.businessUser.paymentGateway == "eway") {
      const eWAY = (window as any).eWAY;

      const comp = this;

      eWAY.saveAllFields(() => {
        comp.paymentLoader = false;

        if (
          comp.ewaySecureFieldCode == null ||
          comp.ewaySecureFieldCode == undefined ||
          comp.ewaySecureFieldCode.trim().length < 5
        ) {
          comp.paymentLoader = false;
          comp.isSuccess = false;
          comp.headerTitle = "Error!";
          comp.bodyMessage = "Missing card information!";
          comp.showDanger(comp.contentDialog);
          comp.changeDetectorRefs.detectChanges();
        } else if (comp.ewayErrors != null && comp.ewayErrors != undefined) {
          comp.paymentLoader = false;
          comp.isSuccess = false;
          comp.headerTitle = "Error!";
          comp.bodyMessage =
            "Wrong card information!" + " Codes: " + comp.ewayErrors;
          comp.showDanger(comp.contentDialog);
          comp.changeDetectorRefs.detectChanges();
        } else {
          payment.token = comp.ewaySecureFieldCode;
          comp.processPayment(this.payment);
        }
      }, 2000);
    } else {
      // this.paymentIntent(this.payment);
      // (window as any).Stripe.card.createToken(
      //   {
      //     number: payment.cardNumber,
      //     exp_month: payment.expMonth,
      //     exp_year: payment.expYear,
      //     cvc: payment.cvv,
      //   },
      //   (status: number, response: any) => {
      //     if (status === 200) {
      //       this.paymentLoader = false;
      //       const token = response.id;
      //       payment.token = token;
      //       // Logger.log('Card info done' + JSON.stringify(this.payment));
      //       this.processPayment(this.payment);
      //     } else if (status === 402) {
      //       this.paymentLoader = false;
      //       this.isSuccess = false;
      //       this.headerTitle = "Error!";
      //       this.bodyMessage = "Wrong card information!" + " Code: " + status;
      //       this.showDanger(this.contentDialog);
      //       this.changeDetectorRefs.detectChanges();
      //     } else {
      //       this.paymentLoader = false;
      //       this.isSuccess = false;
      //       this.headerTitle = "Error!";
      //       this.bodyMessage = "Card Payment Failed!" + " Code: " + status;
      //       this.showDanger(this.contentDialog);
      //       this.changeDetectorRefs.detectChanges();
      //     }
      //   }
      // ),
      //   (error) => {
      //     this.paymentLoader = false;
      //   };
    }
  }

  // savePaymentProcess() {
  //   this.orderService.savePayment(this.payment).subscribe((res1) => {
  //     if (res1.status === 200) {
  //       this.payment = res1.body;
  //       Logger.log('res1 save payment : ' + JSON.stringify(res1.body));
  //       Logger.log('s : ' + JSON.stringify(this.order));

  //       this.order.paymentId = this.payment.id;
  //       this.order.modeOfPayment = this.payment.paymentMode;
  //       this.placeOrder();
  //     } else {
  //       this.paymentLoader = false;
  //     }
  //   });
  // }

  ewaySecureFieldCallback(event: any) {
    Logger.info("ewaySecureFieldCallback", event);

    if (event.fieldValid) {
      this.ewaySecureFieldCode = event.secureFieldCode;
      this.ewayErrors = null;
    } else {
      Logger.error("Error in eway fields", event);
      this.ewayErrors = event.errors;

      // if (event.errors.indexOf('V6148') >= 0) {
      //   this.ewayInitComplete = false;
      //   this.initEWay();
      // }
    }
  }

  initEWay() {
    if (!this.ewayInitComplete) {
      this.ewayInitComplete = true;
      Logger.info("init eWay");

      if (this.businessUser == null || this.businessUser == undefined) {
        return;
      }

      if (
        this.businessUser.paymentGatewayPublicKey == null ||
        this.businessUser.paymentGatewayPublicKey == undefined ||
        this.businessUser.paymentGatewayPublicKey.trim().length < 5
      ) {
        return;
      }

      const publicApiKey = this.businessUser.paymentGatewayPublicKey;
      const eWAY = (window as any).eWAY;

      var rowStyles = "";
      var groupStyles = "";

      var groupFieldConfig = {
        publicApiKey: publicApiKey,
        fieldDivId: "eway-secure-panel",
        fieldType: "group",
        styles: groupStyles,
        layout: {
          rows: [
            {
              styles: rowStyles,
              cells: [
                {
                  colSpan: 12,
                  styles: "margin-top: 15px;",
                  label: {
                    fieldColSpan: 12,
                    text: "Name On Card*",
                  },
                  field: {
                    fieldColSpan: 12,
                    fieldType: "name",
                  },
                },
                {
                  colSpan: 12,
                  styles: "margin-top: 15px;",
                  label: {
                    fieldColSpan: 12,
                    text: "Card Number:",
                    styles: "",
                  },
                  field: {
                    fieldColSpan: 12,
                    fieldType: "card",
                  },
                },
              ],
            },
            {
              styles: rowStyles,
              cells: [
                {
                  colSpan: 12,
                  styles: "margin-top: 15px;",
                  label: {
                    fieldColSpan: 12,
                    text: "Expiry:",
                    styles: "",
                  },
                  field: {
                    fieldColSpan: 12,
                    fieldType: "expiry",
                  },
                },
                {
                  colSpan: 12,
                  styles: "margin-top: 15px;",
                  label: {
                    fieldColSpan: 12,
                    text: "CVV Number:",
                    styles: "",
                  },
                  field: {
                    fieldColSpan: 12,
                    fieldType: "cvn",
                  },
                },
              ],
            },
          ],
        },
      };

      eWAY.setupSecureField(
        groupFieldConfig,
        this.ewaySecureFieldCallback.bind(this)
      );
    }
  }

  showSuccess(content) {
    this.alertType = "success";
    this.showAlert = true;
  }
  showWarning(content) {
    this.alertType = "warning";
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
      this.changeDetectorRefs.detectChanges();
    }, 3000);
  }
  showDanger(content) {
    this.alertType = "danger";
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
      this.changeDetectorRefs.detectChanges();
    }, 3000);
  }
  // Closed() {
  //   this.modalService.dismissAll();
  // }

  // ClosedSuccess() {
  //   this.modalService.dismissAll();
  //   // this.router.navigate(["/"]);
  // }
  

  placeOrder() {
    
    this.paymentLoader = true;
    this.order.advanceAmount = 0;
    if (this.order.taxAmount == null || this.order.taxAmount == undefined) {
      this.order.taxAmount = 0;
      
    }
    this.order.currency = this.businessUser.localCurrency;
    if (this.order.firstName || this.order.lastName) {
      this.order.customerName = `${this.order.firstName} ${this.order.lastName}`.trim();
  } 
    this.order.shipToAddress = this.address;
    this.order.refundAmount = 0;
  this.order.orderPaymentStatus = "NotPaid"
    this.order.taxDetails = this.taxDetailsArray;
    this.order.serviceChargeAmount= 0;
    if(this.order.requiredDate == undefined || this.order.requiredDate == null){
      this.order.requiredDate = new Date().getTime().toString();
 }
 if(this.order.requiredTime == undefined || this.order.requiredTime == null){

 this.order.requiredTime = this.getOrderTimeformatAMPM(new Date());
}
    if (this.homeDelivery == true) {
      this.order.deliveryMethod = "Home Delivery";
      this.order.locationName = '';
    } 
    else if (this.pickfromstore == true) {
      this.order.deliveryMethod = "Take Away";
      this.order.locationName = '';
    } else if (this.roomOrder == true){
      this.order.deliveryMethod = "Room Order";
      this.order.locationName = '';
    }
    else {
      this.order.deliveryMethod = "Dine In";
    }
    if (this.order.deliveryMethod === "Dine In" && this.TimeSlotDetails != null && this.TimeSlotDetails != undefined && this.TimeSlotDetails.time != null) {
      this.order.orderSlot = this.TimeSlotDetails.time;
  }
  if (this.order.deliveryMethod == "Room Order") {
    this.payment.businessServiceName = "Restaurants";
    this.payment.referenceNumber = this.booking?.propertyReservationNumber;
    // if(this.order.id == null && this.order.discountPercentage == 100){
    //   this.order.complimentary = true;
    //   this.payment.description = "(Complimentary)";
    // } else if (this.order.id != null && this.order.discountPercentage == 100) {
    //   this.order.complimentary = true;
    //   this.payment.description = this.order.bookOneOrderId+"\n(Complimentary)";
    // } else {
    //   this.payment.description = this.order.bookOneOrderId;
    // }

    if(this.payment.status === "NotPaid"){
        this.payment.paymentMode = "BillToRoom"
    }
    if(this.order.customerName != null){
      this.payment.customerName = this.order.customerName;
    }
    if (this.order.roomNo != null && this.order.roomNo != undefined) {
      this.payment.roomNumber = this.order.roomNo;
    }
  } 
  this.order.externalSite = "Website";

  this.orderCurrentDate();

    Logger.log("order placed: " + JSON.stringify(this.order));
    this.orderService.order(this.order).subscribe(
      (res1) => {
        // Logger.log('order Responce: ' + JSON.stringify(res1));

        if (res1.status === 200) {
          // this.createAuditReport(null, this.order, AUDIT_ORDER_CREATE);
          this.token.clearProductCart();
          this.token.clearOrderCart();  
            this.token.clearProductCarts();
            this.addToCartList = [];
            this.paymentLoader = false;
            this.submitButtonDisable = true;
            this.changeDetectorRefs.detectChanges();
         
          // setTimeout(() => {
           
           
          // }, 3000);
          
          
          // this.isSuccess = true;
          // this.headerTitle = "Success!";
          // this.bodyMessage =
          //   "Order Placed Successfully! Your order number is: " +
          //   res1.body.bookOneOrderId +
          //   ".";
          // this.showSuccess(this.contentDialog);
          this.router.navigateByUrl(`/order-success?orderId=${res1.body.bookOneOrderId}`);

          setTimeout(() => {
            this.showAlert = false;
            this.changeDetectorRefs.detectChanges();
           
          }, 3000);

         
        } else {
          this.paymentLoader = false;
          this.isSuccess = false;
          this.headerTitle = "Error!";
          this.bodyMessage = "Order Failed!" + " Code: " + res1.status;
          this.showDanger(this.contentDialog);
          this.changeDetectorRefs.detectChanges();
        }

      //   if (this.order.id === undefined) {
      //     this.order.id = res1.body.id;
      //     this.order.bookOneOrderId = res1.body.bookOneOrderId;
      //     this.order.orderLineDtoList = res1.body.orderLineDtoList;
      //     console.log("this.order.orderLineDtoList",this.order.orderLineDtoList)
      //     this.generateKot(this.order.orderLineDtoList);

      // }
      },
      (error) => {
        this.paymentLoader = false;

        this.isSuccess = false;
        this.headerTitle = "Error!";
        this.bodyMessage = "Failed! Code: " + error.status + "";
        this.showDanger(this.contentDialog);

        this.changeDetectorRefs.detectChanges();
      }
    );
  }
  
  paymentIntent(payment: Payment) {
    this.paymentLoader = true;
    payment.date = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
    this.orderService.paymentIntent(payment).subscribe((response) => {
      this.paymentLoader = false;
      if (response.status === 200) {
        this.payment = response.body;
  
      }
    });
  }
  loadStripe() {
    // Your Stripe public key
    const stripe = Stripe(this.businessUser.paymentGatewayPublicKey);

    // Create `card` element that will watch for updates
    // and display error messages
    const elements = stripe.elements();
    const card = elements.create("card");
    card.mount("#card-element");
    card.addEventListener("change", (event) => {
      const displayError = document.getElementById("card-error");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    // Listen for form submission, process the form with Stripe,
    // and get the
    const paymentForm = document.getElementById("payment-form");
    paymentForm.addEventListener("submit", (event) => {
      event.preventDefault();

      payWithCard(stripe, card, this.payment.clientSecret);
    });

    const payWithCard = function (stripe, card, clientSecret) {
      loading(true);
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: card,
          },
        })
        .then(function (result) {
          if (result.error) {
            // Show error to your customer
            showError(result.error.message);
          } else {
            // The payment succeeded!
            loading(false);
      
            orderComplete();
          }
        });
    };

    const loading = function (isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    };
    const showError = function (errorMsgText) {
      loading(false);
      var errorMsg = document.querySelector("#card-error");
      errorMsg.textContent = errorMsgText;
      setTimeout(function () {
        errorMsg.textContent = "";
      }, 6000);
    };
    let orderComplete = function () {
      window.angularComponentReference.zone.run(() => {
        window.angularComponentReference.loadAngularFunction();
      });
    };
  }
  stripePaymentSuccess() {
    this.orderService.savePayment(this.payment).subscribe((response) => {
      this.paymentLoader = false;
      if (response.status === 200) {
        this.payment = response.body;
        

        this.order.paymentId = response.body.id;
        this.order.modeOfPayment = this.payment.paymentMode;

        Logger.log("Card info done" + JSON.stringify(this.payment));
        this.changeDetectorRefs.detectChanges();
        this.placeOrder();
      }
    });
  }
}
