import { Component, Input, Output, EventEmitter, OnInit  } from '@angular/core';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'cartOne',
  template: `
  <h5 style="background-color: var(--secondary) ;color:white">Shopping Cart ({{calculateQuantity()}}) <span style="float: right;">{{ calculatePrice() | number : '1.2-2'}}</span> </h5>
  <cartOne-product *ngFor="let product of products" [product]="product" [isDisabled]="false" (productRemoved)="removeProduct($event)" (productVariationRemoved)="removeProductVariatiion($event)"><cartOne-product>
  `,
  styles: [`:host > h5{
    padding: 20px;
  }
  :host {
    width: 100%;
  }
  `]
})
export class CartOneComponent implements OnInit {

  totalQuantity: number ;
  totalPrice: number;
  totalExtraPrice:number;
  @Input() products: any[];
  @Input() totalAmount: any;
  @Output() productRemoved = new EventEmitter();
  @Output() productVariationRemoved = new EventEmitter();

  constructor(private token: TokenStorage) {

  }

  ngOnInit() {

  }

  calcTotal() {
    return this.products.reduce((acc, prod) => acc += prod.quantity , 0);
  }
  removeProduct(product , index) {
    this.productRemoved.emit(product);
  }
  removeProductVariatiion(product , index) {
    this.productVariationRemoved.emit(product);
  }
  calculateQuantity() {
    this.totalQuantity = 0;

     for (let i = 0 ; i < this.products.length ; i++) {

      if (this.products[i].variationDtoList != undefined) {
        for (let j = 0 ; j < this.products[i].variationDtoList.length ; j++) {
          this.totalQuantity = this.totalQuantity + 1;
        }
      } else {
        this.totalQuantity = this.totalQuantity + 1;
      }

     }

     return this.totalQuantity;
  }

  calculatePrice() {
    this.totalPrice = 0;
    this.totalExtraPrice =0;
     for (let i = 0 ; i < this.products.length ; i++) {

        // if (this.products[i].totalPrice != undefined || this.products[i].totalPrice != null) {
        //   this.totalPrice = this.totalPrice + this.products[i].totalPrice ;
        // }
        if (this.products[i].totalPrice != undefined || this.products[i].totalPrice != null|| this.products[i]?.extraProductPrice != undefined || this.products[i]?.extraProductPrice  != null) {
          this.totalPrice = this.totalPrice + this.products[i].totalPrice+this.products[i].extraProductPrice;
        } else {
          this.totalPrice = this.totalPrice + this.products[i].totalPrice ;
          
        }
        

        if (this.products[i].variationDtoList != undefined) {
          for (let j = 0 ; j < this.products[i].variationDtoList.length ; j++) {
            this.totalPrice = this.totalPrice + this.products[i].variationDtoList[j].totalPrice;
          }
        }

     }
     this.token.saveProductCart(this.products);
     return this.totalPrice;
  }

}