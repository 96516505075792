// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // appId: 'BookOneLocal',
  // apiUrl: 'https://testapi.bookonelocal.co.nz/api-bookone',
  // apiUrlAddress: 'https://testapi.bookonelocal.co.nz/api-address',
  // apiUrlPromotion: 'https://testapi.bookonelocal.co.nz/promotion-api',
  // parentOrganisationId: 1,
  // dashboardUrl: 'https://testapp.bookonelocal.co.nz',
  // appUrl: 'https://test.bookonelocal.co.nz',
  // shopUrl: 'Credencesoft-Food-Grocery',
  // customerAppUrl: 'https://bookone-customer-app.web.app',
  // businessAppUrl: 'https://bookone-mobile-app.web.app',
  // androidAppUrl: 'https://play.google.com/store/apps/details?id=nz.co.bookonelocal.customer',
  // iosAppUrl: 'https://apps.apple.com/us/app/bookone-local/id1528910778',
  // country: 'nz',
  // showLog: true,
  // tagManagerId: 'GTM-PMNSFZS',
  // googleKey: 'AIzaSyBYGJ1SMIW5BIny9Af7eoozmgSGZShhUuE'
  production: true,
  appId: 'BookOneLocal',
  apiUrl: 'https://api.bookone.io/api-bookone',
  apiUrlAddress: 'https://api.bookone.io/api-address',
  apiUrlPromotion: 'https://api.bookone.io/promotion-api',
  parentOrganisationId: 1,
  dashboardUrl: 'https://app.bookonelocal.in',
  appUrl: 'https://bookonelocal.in',
  shopUrl: 'BookOneLocal-Food-Grocery',
  customerAppUrl: 'https://bookone-customer-app.web.app',
  businessAppUrl: 'https://bookone-mobile-app.web.app',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=nz.co.bookonelocal.customer',
  iosAppUrl: 'https://apps.apple.com/us/app/bookone-local/id1528910778',
  country: 'nz',
  showLog: false,
  tagManagerId: 'GTM-PMNSFZS',
  googleKey: 'AIzaSyBYGJ1SMIW5BIny9Af7eoozmgSGZShhUuE'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
